/**************************************************************************************************
//
//  Description:  combines reducers for User slice of Redux store
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   30.10.20 Joel Benford               Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { combineReducers } from "redux";
import searchHistoryReducer from './searchHistory';
import lastRouteReducer from "./lastRoute";
// # endregion imports

export default combineReducers({
  searchHistory: searchHistoryReducer,
  lastRoute: lastRouteReducer
});