import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Alert } from '@material-ui/lab';

const ErrorDisplay = (props) => {

  return (
    Array.isArray(props.errs) && props.errs.map((err,idx) => {
      return (<Alert key={idx} severity="error">{err}</Alert>) })
  );
}  

const ResetUserPasswordDialog = ({ isOpen, user, updatePasswordHandler, handleCloseDialog }) => {

  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const [errors, setErrors] = useState()

  const minPasswordLength = 8;

  const PASSWORD_TOO_SHORT = `passwords must be at least ${minPasswordLength} characters long`;
  const PASSWORD_DONT_MATCH = "passwords dont match"

  useEffect(() => {
  }, [errors]);

  const handleSubmit = async () => {

    let okToSubmit = true;
    let updatedErrors = errors ? [ ...errors ] : [];
    console.log("updatederrors ", updatedErrors);

    updatedErrors = updatedErrors.filter((x) => { return x != PASSWORD_TOO_SHORT })
    if (password && password.length < minPasswordLength) {
      updatedErrors.push(PASSWORD_TOO_SHORT);
      okToSubmit = false;
    }
    
    updatedErrors = updatedErrors.filter((x) => { return x != PASSWORD_DONT_MATCH })
    if (password !== confirmpassword) {
      updatedErrors.push(PASSWORD_DONT_MATCH);
      okToSubmit = false;
    }

    if (okToSubmit) {

      updatedErrors = await updatePasswordHandler(user.id, password)
      setErrors(updatedErrors);

      console.log("validated errors", errors, updatedErrors);

      if (updatedErrors.length == 0)
        handleCloseDialog();
    }
    else {
      setErrors(updatedErrors);
		}
	}


  return (
    <Dialog open={isOpen} aria-labelledby="password-reset-dialog">
      <DialogTitle id="password-reset-dialog">Reset Password</DialogTitle>
      <DialogContent>
        <ErrorDisplay errs={errors} />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          inputProps={{ readOnly: true, }}
          disabled
          value={user.email}
          fullWidth
        />
        <TextField
          margin="dense"
          id="new_password"
          label="Password"
          type="password"
          inputProps={{
            maxLength: 30,
            autoComplete: 'new-password',
            form: { autocomplete: 'off',}, }}
          defaultValue={password}
          onChange={(e) => { setPassword(e.target.value); }}
          fullWidth
        />
        <TextField
          margin="dense"
          id="confirmpassword"
          label="Confirm Password"
          type="password"
          inputProps={{
            maxLength: 30,
            autoComplete: 'new-password',
            form: { autocomplete: 'off', },
          }}

          defaultValue={confirmpassword}
          onChange={(e) => { setConfirmPassword(e.target.value); }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
          </Button>
        <Button onClick={handleSubmit} color="primary">
          Update
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetUserPasswordDialog