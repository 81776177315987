/* #region header */
//**************************************************************************************************
//
//  Description: Display the error message
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   08.02.21 Sean Flook         WI38232 Initial version.
//    002   09.02.21 Sean Flook         WI38232 Changed variant to caption.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import { Typography, Grid } from "@material-ui/core";
/* #endregion imports */

const AAErrorDisplay = ({ errorText }) => {

  return (errorText !== null) ? (
    <React.Fragment>
      <Grid item xs="4" justify="left"></Grid>
      <Grid item xs="8" justify="left">
        <Typography variant="caption" color="error">{errorText}</Typography>
      </Grid>
    </React.Fragment>
  ) : "";
}

export default AAErrorDisplay;