/* #region header */
/**************************************************************************************************
//
//  Description:  Wrapper for the history
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   20.01.21 Sean Flook         WI39037 Initial version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import { createBrowserHistory } from "history";
/* #endregion imports */

export default createBrowserHistory();