/**************************************************************************************************
//
//  Description:  search history slice of Redux store
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   30.10.20 Joel Benford               Initial Revision (stub, work in progress).
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { createSlice } from "@reduxjs/toolkit";
// #endregion imports

//State
let lastId = 0;
const slice = createSlice({
  name: "searchHistory",
  initialState: [],
  reducers: {
    searchAdded: (searchHistory, action) => {
      searchHistory.push({
        id: ++lastId,
        term: action.payload
      });
    }
  }
});

// Actions
export const { searchAdded } = slice.actions;

// Reducers
export default slice.reducer;

// Selectors
export const getSearchHistoryLength = state => {
 return state.user.searchHistory.length;
}