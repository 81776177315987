/**************************************************************************************************
//
//  Description:  overall config reducer
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   30.10.20 Joel Benford               Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { combineReducers } from "redux";
import gazApiReducer from './gazApi';
/* #endregion imports */

export default combineReducers({
  gazApi: gazApiReducer
});