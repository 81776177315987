/**************************************************************************************************
//
//  Description:  combines reducers for UI slice of Redux store
//
//  Copyright:    © 2020-21 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   21.01.21 Joel Benford        WI39069 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { combineReducers } from "redux";
import lookupsRefreshedReducer from './lookupsRefreshed'
// # endregion imports

export default combineReducers({
  lookupsRefreshed: lookupsRefreshedReducer
});