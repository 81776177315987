/**************************************************************************************************
//
//  Description:  entities/localities slice of Redux store
//
//  Copyright:    © 2020-2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   13.01.21 Joel Benford        WI39064 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { createSlice } from "@reduxjs/toolkit";
//#endregion imports

//State
const slice = createSlice({
  name: "localities",
  initialState: [],
  reducers: {
    localitiesSet: (localities, action) => {
      //console.log("trace localitiesSet", localities, action);
      return action.payload
    }
  }
});

// Actions
export const { localitiesSet } = slice.actions;

// Reducers
export default slice.reducer;
