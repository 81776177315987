//#region header */
/**************************************************************************************************
//
//  Description:  Main Layout page
//
//  Copyright:    � 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   06.01.21 Sean Flook         WI39037 Removed collapsed property as not being used.
//    003   19.01.21 Sean Flook                 Corrected spelling of Gazetteer.
//    004   20.01.21 Sean Flook         WI39037 Use wrapped history.
//    005   03.03.21 Sean Flook         WI39183 Removed footer as no longer required.
//    006   11.03.21 Peter Bryden               Added in theme, although not sure if this is superceded by stylesprovider
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React from "react";
import styled from "styled-components";
import { StylesProvider } from "@material-ui/core/styles";

import Layout, {
  Root,
  getHeader,
  getDrawerSidebar,
  getSidebarContent,
  getContent,
  getFooter,
} from "@mui-treasury/layout";

import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";

import AANavContent from "./components/AANavContent";
import AANavHeader from "./components/AANavHeader";
import AAHeader from "./components/AAHeader";

import { Router, withRouter } from "react-router-dom";
import history from "./history";
import PageRouting from "./PageRouting";

import WithStreet from './components/withStreet';


import { ThemeProvider } from '@material-ui/core/styles';

import "@progress/kendo-theme-material/dist/all.css";
import "./App.css";


/* #endregion imports */

/* region consts */
const scheme = Layout();
const Header = getHeader(styled);
//const history = createBrowserHistory();

const DrawerSidebar = getDrawerSidebar(styled);
const SidebarContent = getSidebarContent(styled);
const Content = getContent(styled);
const Footer = getFooter(styled);
/* #endregion consts */

scheme.configureHeader((builder) => {
  builder.registerConfig("xs", {
    position: "sticky",
  });
});

scheme.configureEdgeSidebar((builder) => {
  builder
    .create("main_sidebar", { anchor: "left" })
    .registerPermanentConfig("md", {
      width: 64, // px, (%, rem, em is compatible)
      collapsible: false,
      collapsedWidth: 64,
    });
});

//history.listen(() => {
//  if (history.location.state && history.location.state !== "")
//    console.log("DEBUG history", history.location.state, history.location.pathname.replace("/gazetteer/", ""));
//    //this.setState({ street: history.location.state, usrn: history.location.pathname.replace("/gazetteer/", "") });
//});

const HeaderWithStreet = WithStreet(AAHeader);
const theme = {};
let unlisten;

class App extends React.Component {
  state = {
    street: '',
    usrn: 0
  }

  componentDidMount() {
    unlisten = history.listen(() => {
      if (history.location.state && history.location.state !== "") {
        this.setState({ street: history.location.state, usrn: history.location.pathname.replace("/gazetteer/", "") });
      }
    });
  }

  componentWillUnmount() {
    unlisten();
  }

  handleStreetChange = (usrnVal, streetVal) => {
    this.setState({ street: streetVal, usrn: usrnVal });
    if (usrnVal && usrnVal > 0)
      history.push(`/gazetteer/${usrnVal}`)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
        <Root scheme={scheme}>
          {({ state: { sidebar } }) => (
            <>
              <Router history={history}>
                <CssBaseline />
                <Header>
                  <Toolbar>
                    <HeaderWithStreet streetName={this.state.street} usrn={this.state.usrn} handleChange={this.handleStreetChange} />
                  </Toolbar>
                </Header>
                <DrawerSidebar sidebarId="main_sidebar">
                  <SidebarContent>
                    <AANavHeader />
                    <AANavContent usrn={this.state.usrn} history={history} />
                  </SidebarContent>
                </DrawerSidebar>
                <Content>
                  <PageRouting />
                </Content>
              </Router>
            </>
          )}
        </Root>
        </StylesProvider>
        </ThemeProvider>
    );
  }
}

export default withRouter(App);
