//#region
/**************************************************************************************************
//
//  Description: Container for the data about street
//
//  Copyright:    � 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   30.10.20 Joel Benford               Handle date as date (not integer) from API
//    003   09.12.20 Sean Flook         WI38224 Disable the controls so users cannot edit the street data and add in missing controls.
//    004   10.12.20 Sean Flook         WI38224 Removed comments from render section.
//    005   15.01.21 Joel Benford       WI39064 Cached lookups in redux store.
//    006   19.01.21 Joel Benford       WI39064 Cached lookups in redux store - typo fix.
//    007   26.01.21 Joel Benford       WI39069 Check API for whether lookups need refresh
//    008   09.02.21 Sean Flook         WI38232 Changed label components to Typography components.
//    009   09.02.21 Sean Flook         WI38232 Change required to prevent the horizontal scrollbar from appearing.
//    010   19.02.21 Sean Flook         WI39185 Adjusted the width.
//    011   22.02.21 Joel Benford       WI39069 Factored out checking and loading lookups to service module
//    012   25.02.21 Joel Benford       WI39069 Fed dropdowns data directly from redux
//    013   09.03.21 Sean Flook         WI39240 Corrected width.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */

import React, { useEffect } from "react"; //SF WI38224
import moment from 'moment';
import PropTypes from "prop-types";
import { Typography, Grid, Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DropDownList } from "@progress/kendo-react-dropdowns"; //SF WI38224

import { store } from "./../store/store";
import { checkGetLookupsAsync } from './../services/lookupsService';

import AAAvatarSelect from "../components/AAAvatarSelect";

import { GetOSGSwaOrgRefs_URL, GetLocalities_URL, GetTowns_URL, GetAdministrativeAreas_URL, GetStreetRefs_URL, GetOSGLanguages_URL, GetLookupsLastChangedURL } from '../configuration/AAConfig' //SF WI38224  //JB WI39069

/* #endregion imports */

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formrow: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    alignItems: "center",
  },
}));

//SF WI38224++
function TabPanel(props) {
  const { children, value, selectedValue, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== selectedValue}
      id={`aa-tabpanel-${value}`}
      aria-labelledby={`aa-tab-${value}`}
      {...other}
    >
      {value === selectedValue && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function LanguageTabProps(name) {
  return {
    id: `aa-tab-${name}`,
    "aria-controls": `aa-tabpanel-${name}`,
    value: `${name}`,
  };
}

const LanguageTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 12,
    maxWidth: 60,
    marginRight: theme.spacing(2),
  },
  selected: { fontWeight: theme.typography.fontWeightBold },
}))((props) => <Tab {...props} />);
//SF WI38224--

const StreetTypeDropDown = (props) => {

  const [data, setData] = React.useState(props.data); //SF WI38224
  const [selectedKey, setSelectedKey] = React.useState(props.selectedKey);

  return (
    <AAAvatarSelect
      data={data}
      avatarField="stRefType" //SF WI38224
      textField="stDesc" //SF WI38224
      keyField="stRefType" //SF WI38224
      placeholder="choose street type"
      disabled="true" //SF WI38224
      selectedKey={selectedKey}
      colour="brown"
      className="form__component"
    />
  );
}

//SF WI38224++
const StreetLookupDropDown = (props) => {
  const [data, setData] = React.useState(props.data);
  const [textField, setTextField] = React.useState(props.textField);
  const [dataItemKey, setDataItemKey] = React.useState(props.dataItemKey);
  const [placeholder, setPlaceholder] = React.useState(props.placeholder);
  const [selectedKey, setSelectedKey] = React.useState(props.selectedKey);
  const [width, setwidth] = React.useState(props.width);

  const selectedValue = data.find(x => x[dataItemKey] === selectedKey);
  const dropdownwidth = !width ? "19vw" : width;

  return (
    <DropDownList
      data={data}
      textField={textField}
      dataItemKey={dataItemKey}
      placeholder={placeholder}
      disabled="true"
      className="form__component"
      value={selectedValue}
    />
  );
}
//SF WI38224--

const StreetForm = (props) => {
  const classes = useStyles();

  //SF WI38224++
  const [streetData, setStreetData] = React.useState(props.data);
  //console.log("street tab data ", streetData);
  const streetDescriptors = streetData.streetDescriptors;
  const [StreetDescriptorData_ENG, setStreetDescriptorData_ENG] = React.useState();
  const [StreetDescriptorData_GAE, setStreetDescriptorData_GAE] = React.useState();
  const [Lookup_OSGSwaOrgRefs, setLookup_OSGSwaOrgRefs] = React.useState();
  const [Lookup_Localities, setLookup_Localities] = React.useState();
  const [Lookup_Towns, setLookup_Towns] = React.useState();
  const [Lookup_AdministrativeAreas, setLookup_AdministrativeAreas] = React.useState();
  const [Lookup_StreetRefs, setLookup_StreetRefs] = React.useState();
  const [Lookup_OSGLanguages, setLookup_OSGLanguages] = React.useState();

  const [isloaded, setisLoaded] = React.useState(false);

  const [selectedLanguagePanel, setSelectedLanguagePanel] = React.useState("eng");

  useEffect(() => {

    let isCancelled = false;

    const LoadLookups = async () => {

      await checkGetLookupsAsync(); //WI39069 Call service which will load lookups into redux if needed

      const Lookups = [
        { "url": await GetOSGSwaOrgRefs_URL(), "data": Lookup_OSGSwaOrgRefs, "id": "Lookup_OSGSwaOrgRefs" },
        { "url": await GetLocalities_URL(), "data": Lookup_Localities, "id": "Lookup_Localities" },
        { "url": await GetTowns_URL(), "data": Lookup_Towns, "id": "Lookup_Towns" },
        { "url": await GetAdministrativeAreas_URL(), "data": Lookup_AdministrativeAreas, "id": "Lookup_AdministrativeAreas" },
        { "url": await GetStreetRefs_URL(), "data": Lookup_StreetRefs, "id": "Lookup_StreetRefs" },
        { "url": await GetOSGLanguages_URL(), "data": Lookup_OSGLanguages, "id": "Lookup_OSGLanguages" }
      ];

      Lookups.forEach(async (lookup) => {
        if (!isCancelled && !lookup.data) {
          switch (lookup.id) {
            case "Lookup_OSGSwaOrgRefs":
              setLookup_OSGSwaOrgRefs(await store.getState().entities.osgSwaOrgRefs);
              break;
            case "Lookup_Localities":
              setLookup_Localities(await store.getState().entities.localities);
              break;
            case "Lookup_Towns":
              setLookup_Towns(await store.getState().entities.towns);
              break;
            case "Lookup_AdministrativeAreas":
              setLookup_AdministrativeAreas(await store.getState().entities.administrativeAreas);
              break;
            case "Lookup_StreetRefs":
              setLookup_StreetRefs(await store.getState().entities.streetRefs);
              break;
            case "Lookup_OSGLanguages":
              setLookup_OSGLanguages(await store.getState().entities.osgLanguages);
              break;
          }
        }
      }); //end for each lookup

      streetDescriptors.forEach(async (streetDescriptor) => {
        if (!isCancelled) {
          switch (streetDescriptor.language) {
            case "ENG": setStreetDescriptorData_ENG(streetDescriptor); break;
            case "GAE": setStreetDescriptorData_GAE(streetDescriptor); break;
          }
        }
      });

    } // end of LoadLookups declaration

    if (!isloaded) {
      LoadLookups();
    }

    return () => {
      isCancelled = true;
    };

  }, [isloaded])

  const bIsloaded = !(!Lookup_OSGSwaOrgRefs || !Lookup_Localities || !Lookup_Towns || !Lookup_AdministrativeAreas || !Lookup_StreetRefs || !Lookup_OSGLanguages);

  if (bIsloaded && !isloaded) {
    setisLoaded(true);
  }

  const handleLanguageChange = (event, newValue) => {
    setSelectedLanguagePanel(newValue);
  };

  return isloaded && streetData ? (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        alignItems="baseline"
      >
        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Language</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <Tabs
              value={selectedLanguagePanel}
              onChange={handleLanguageChange}
              aria-label="Language Tabs"
              variant="standard"
              indicatorColor="primary"
            >
              {StreetDescriptorData_ENG ? <LanguageTab label="ENG" {...LanguageTabProps("eng")} /> : ""}
              {StreetDescriptorData_GAE ? <LanguageTab label="GAE" {...LanguageTabProps("gae")} /> : ""}
            </Tabs>
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Name or descriptor</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <Typography className="form__component" variant="body2">{selectedLanguagePanel === "gae" ? StreetDescriptorData_GAE.descriptor : StreetDescriptorData_ENG.descriptor}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Locality</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            {selectedLanguagePanel === "gae" ? <StreetLookupDropDown data={store.getState().entities.localities} textField="loc" dataItemKey="locRef" placeholder="Choose Locality" selectedKey={StreetDescriptorData_GAE.localityRef} className="form__component" /> : <StreetLookupDropDown data={Lookup_Localities} textField="loc" dataItemKey="locRef" placeholder="Choose Locality" selectedKey={StreetDescriptorData_ENG.localityRef} className="form__component" />}
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Town</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            {selectedLanguagePanel === "gae"
              ? <StreetLookupDropDown data={store.getState().entities.towns} textField="town" dataItemKey="townRef" placeholder="Choose Town" selectedKey={StreetDescriptorData_GAE.townRef} className="form__component" />
              : <StreetLookupDropDown data={store.getState().entities.towns} textField="town" dataItemKey="townRef" placeholder="Choose Town" selectedKey={StreetDescriptorData_ENG.townRef} className="form__component" />}
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Island</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <Typography className="form__component" variant="body2">{selectedLanguagePanel === "gae" ? StreetDescriptorData_GAE.island : StreetDescriptorData_ENG.island}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Admin area</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            {selectedLanguagePanel === "gae"
              ? <StreetLookupDropDown data={store.getState().entities.administrativeAreas} textField="county" dataItemKey="countyRef" placeholder="Choose Administrative Area" selectedKey={StreetDescriptorData_GAE.administrativeAreaRef} className="form__component" />
              : <StreetLookupDropDown data={store.getState().entities.administrativeAreas} textField="county" dataItemKey="countyRef" placeholder="Choose Administrative Area" selectedKey={StreetDescriptorData_ENG.administrativeAreaRef} className="form__component" />}
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Authority</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <StreetLookupDropDown data={store.getState().entities.osgSwaOrgRefs} textField="swaOrgNameText" dataItemKey="swaOrgRef" placeholder="Choose Local Custodian" selectedKey={streetData.custodianCode} className="form__component" />
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">USRN</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <Typography className="form__component" variant="body2">{streetData.usrn}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Type</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <StreetTypeDropDown data={store.getState().entities.streetRefs} selectedKey={streetData.recordType} className="form__component" />
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Entry Date</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <Typography className="form__component" variant="body2" gutterBottom>{moment(streetData.entryDate).format("DD MMMM YYYY")}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Start Date</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <Typography className="form__component" variant="body2" gutterBottom>{moment(streetData.startDate).format("DD MMMM YYYY")}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">Last Update Date</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <Typography className="form__component" variant="body2" gutterBottom>{moment(streetData.lastUpdatetDate).format("DD MMMM YYYY")}</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.formrow}>
          <Grid item xs="4" justify="left">
            <Typography variant="body2">End Date</Typography>
          </Grid>
          <Grid item xs="4" justify="left">
            <Typography className="form__component" variant="body2" gutterBottom>{streetData.endDate ? moment(streetData.endDate).format("DD MMMM YYYY") : ""}</Typography>
          </Grid>
        </Grid>

      </Grid>
    </div>
  ) : ("Loading Street Data");
};

StreetForm.propTypes = {
  //classes: PropTypes.object.isRequired,
};

export default StreetForm;
