import React, { Component } from 'react';
import App from './App';
import { Container } from 'reactstrap';

import { Route } from 'react-router';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

export default class SecureCheck extends Component {
    static displayName = App.name;

    render() {
        return (
            <div>
                <AuthorizeRoute exact path='/' component={App} />
                 <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
             </div>
       );
    }
}
