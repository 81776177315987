/**************************************************************************************************
//
//  Description: URL data about the api calls we need to make
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   30.10.20 Joel Benford               Cache state in redux store
//    003   10.12.20 Sean Flook         WI38224 Added Street Lookups.
//    004   21.01.21 Sean Flook         WI38232 Changes required for saving the data.
//    005   21.01.21 Joel Benford       WI39069 Add Features controller methods.
//    006   12.03.21 Sean Flook         WI39268 Added GetLastExportDateURL.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    007   27.05.21 Peter Bryden       WI39324 Added GetClosedStreetsWithASDURL & MoveClosedStreetASDsURL.
//    008   07.06.21 Paula Dormer       WI39308 Added the CloseSelectedASDRecords
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */
//#region imports
import { store } from "./../store/store";
import { gazApiBaseSet } from './../store/config/gazApi';
//#endregion imports

async function getGazAPIBase() {

  const storeBase = store.getState().config.gazApi;
  if (storeBase) {
    return storeBase;
  }

  //console.log("gazApiBase not in store, fetching from controller");
  return await fetch("/api/aaconfiguration")
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json())
    .then(
      (result) => {
        const APISettings = result.find(x => x.key === "API");
        store.dispatch(gazApiBaseSet(APISettings.value));
        return store.getState().config.gazApi;
      });
}

export async function GetApiSite(urlController) {

  const gazApiBase = await getGazAPIBase();
  const gazApiUrl = `${gazApiBase}${urlController}`;
  //console.log("gazApiUrl retrieved ", gazApiUrl);
  return gazApiUrl;

  /*
    return await fetch("/api/aaconfiguration")
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
        .then(
            (result) => {
                const APISettings = result.find(x => x.key === "API");
                const apiUrl = `${APISettings.value}${urlController}`;
                return apiUrl;
            });
            */
};

export const GetOperatorsURL = async () => {
  const url = await GetApiSite("/api/User/GetOperators");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export const GetOperatorUsersURL = async () => {
  const url = await GetApiSite("/api/User/GetOperatorUsers");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export const GetOperatorTrunkRoadsURL = async () => {
  const url = await GetApiSite("/api/User/GetOperatorTrunkRoads");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export const GetUserIdURL = async () => {
  const url = await GetApiSite("/api/User/GetUserId");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export const GetCanOperatorEditASDDataURL = async () => {
  const url = await GetApiSite("/api/street/CanOperatorEditAsd");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetSearchStreetURL() {
  const url = await GetApiSite("/api/StreetSearch");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetStreetFromUSRN() {
  const url = await GetApiSite("/api/Street/GetStreetByUSRN");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

//SF WI38224++
export const GetLocalities_URL = async () => {

  const url = await GetApiSite("/api/StreetLookup/GetLocalities");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

export const GetTowns_URL = async () => {

  const url = await GetApiSite("/api/StreetLookup/GetTowns");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

export const GetAdministrativeAreas_URL = async () => {

  const url = await GetApiSite("/api/StreetLookup/GetAdministrativeAreas");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

export const GetStreetRefs_URL = async () => {

  const url = await GetApiSite("/api/StreetLookup/GetStreetRefs");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

export const GetOSGLanguages_URL = async () => {

  const url = await GetApiSite("/api/StreetLookup/GetOSGLanguages");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};
//SF WI38224--

export const GetOSGRoadStatusCodes_URL = async () => {

  const url = await GetApiSite("/api/ASDLookup/GetOSGRoadStatusCodes");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

export const GetOSGSwaOrgRefs_URL = async () => {
  const url = await GetApiSite("/api/ASDLookup/GetOSGSwaOrgRefs");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

export const GetOSGReinstatementTypeCodes_URL = async () => {

  const url = await GetApiSite("/api/ASDLookup/GetOSGReinstatementTypeCodes");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

export const GetOSGSpecialDesignationCodes_URL = async () => {

  const url = await GetApiSite("/api/ASDLookup/GetOSGSpecialDesigCodes");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
};

export async function GetAddMaintenanceResponsibilitiesURL() {
  const url = await GetApiSite("/api/Street/AddMaintenanceResponsibilities");

  return {
    "url": url,
    "type": "POST",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetAddReinstatementCategoriesURL() {
  const url = await GetApiSite("/api/Street/AddReinstatementCategories");

  return {
    "url": url,
    "type": "POST",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetAddSpecialDesignationsURL() {
  const url = await GetApiSite("/api/Street/AddSpecialDesignations");

  return {
    "url": url,
    "type": "POST",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetUpdateMaintenanceResponsibilitiesURL() {
  const url = await GetApiSite("/api/Street/UpdateMaintenanceResponsibilities");

  return {
    "url": url,
    "type": "PUT",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetUpdateReinstatementCategoriesURL() {
  const url = await GetApiSite("/api/Street/UpdateReinstatementCategories");

  return {
    "url": url,
    "type": "PUT",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetUpdateSpecialDesignationsURL() {
  const url = await GetApiSite("/api/Street/UpdateSpecialDesignations");

  return {
    "url": url,
    "type": "PUT",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetDeleteMaintenanceResponsibilitiesURL() {
  const url = await GetApiSite("/api/Street/DeleteMaintenanceResponsibilities");

  return {
    "url": url,
    "type": "DELETE",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetDeleteReinstatementCategoriesURL() {
  const url = await GetApiSite("/api/Street/DeleteReinstatementCategories");

  return {
    "url": url,
    "type": "DELETE",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetDeleteSpecialDesignationsURL() {
  const url = await GetApiSite("/api/Street/DeleteSpecialDesignations");

  return {
    "url": url,
    "type": "DELETE",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetAddUserURL() {
  const url = await GetApiSite("/api/User/AddUser");

  return {
    "url": url,
    "type": "POST",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetAddTrunkRoadUsrnURL() {
  const url = await GetApiSite("/api/User/AddTrunkRoadUsrn");

  return {
    "url": url,
    "type": "POST",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetUpdateOperatorURL() {
  const url = await GetApiSite("/api/User/UpdateOperator");

  return {
    "url": url,
    "type": "PUT",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetUpdateUserURL() {
  const url = await GetApiSite("/api/User/UpdateUser");

  return {
    "url": url,
    "type": "PUT",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetUpdateTrunkRoadUsrnURL() {
  const url = await GetApiSite("/api/User/UpdateTrunkRoadUsrn");

  return {
    "url": url,
    "type": "PUT",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetDeleteUserURL() {
  const url = await GetApiSite("/api/User/DeleteUser");

  return {
    "url": url,
    "type": "DELETE",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetDeleteOperatorUsersURL() {
  const url = await GetApiSite("/api/User/DeleteOperatorUsers");

  return {
    "url": url,
    "type": "DELETE",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetDeleteTrunkRoadUsrnURL() {
  const url = await GetApiSite("/api/User/DeleteTrunkRoadUsrn");

  return {
    "url": url,
    "type": "DELETE",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

//JB WI39069++
export async function GetImportExportHistoriesURL() {
  const url = await GetApiSite("/api/Features/GetImportExportHistories");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetLastExportDateURL() {
  const url = await GetApiSite("/api/Features/GetLastExportDate");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}

export async function GetLookupsLastChangedURL() {
  const url = await GetApiSite("/api/Features/GetLookupsLastChanged");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}
//JB WI39069--

export async function GetClosedStreetsWithASDURL() {
  const url = await GetApiSite("/api/street/GetClosedStreetsWithASD");

  return {
    "url": url,
    "type": "GET",
    "headers": [{ "Content-Type": "text/plain" }]
  };
}


export async function MoveClosedStreetASDsURL() {
  const url = await GetApiSite("/api/street/MoveASDRecordsToDifferentStreet");

  return {
    "url": url,
    "type": "POST",
    "headers": [{ "Content-Type": "text/plain" }]
    };
}

export async function CloseSelectedASDRecordsURL() {
    const url = await GetApiSite("/api/street/CloseSelectedASDRecords");

    return {
        "url": url,
        "type": "POST",
        "headers": [{ "Content-Type": "text/plain" }]
    };
}
