import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';


const EditUserDialog = ({ isOpen, user, operators, submitHandler, cancelHandler }) => {

  const [operatorList, setOperatorList] = useState(operators);
  const [editUser, setEditUser] = useState(user);

  const handleOperatorChange = (event) => {
    const updatedUser = editUser;
    updatedUser.operatorid = event.target.value
    setEditUser(updatedUser);
  };

  
  const handleTextInputChange = (id, value) => {
    const updatedUser = editUser;

    if (id === 'name')
      updatedUser.name = value;

    setEditUser(updatedUser);

    console.log("handletextchange ", id, value, editUser);
  }

 

  return (
    <Dialog open={isOpen} aria-labelledby="user-edit-dialog">
        <DialogTitle id="user-edit-dialog">User Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Please update the user
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            inputProps={{ readOnly: true, }}
            disabled
            value={editUser.email} 
            fullWidth
          />
        <TextField
          margin="dense"
          id="name"
          label="Name"
          type="text"
          inputProps={{ maxLength: 100, }}
          defaultValue={editUser.name}
          onChange={(e) => { handleTextInputChange(e.target.id, e.target.value); }}
          fullWidth
          />
        <FormControl>
          <InputLabel shrink>
            Operator
        </InputLabel>
        <Select
          margin="dense"
          label="Operator"
          id="operatorid"
          value={ editUser.operatorid }
          onChange={handleOperatorChange}
        >
            {operatorList.map((x) => { return (<MenuItem key={x.operatorid} value={x.operatorId}>{x.operatorName}</MenuItem>) })}
        </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelHandler} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>submitHandler(editUser)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default EditUserDialog