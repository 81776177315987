/* #region header */
/**************************************************************************************************
//
//  Description:  ESRI Mapping with Graphic
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   11.01.21 Peter Bryden               Initial Revision.
//    002   14.01.21 Sean Flook         WI38232 Added in the ability to edit the extra graphic layer.
//    003   14.01.21 Sean Flook         WI38232 Missed the draw button.
//    004   14.01.21 Sean Flook         WI38232 Hide the draw button until required and prevent multiple sketch widgets appearing.
//    005   18.01.21 Peter Bryden               Show extragraphic overlay if passed in, if extragraphic is null return to base map and background extent
//    006   20.01.21 Sean Flook         WI39037 Added ability to select a street from the map.
//    007   21.01.21 Sean Flook         WI38232 Changes required for saving the data.
//    008   25.01.21 Sean Flook         WI38232 Initial check-in for changes required for saving ASD records.
//    009   25.01.21 Sean Flook         WI38232 Handle when we have 4 opening and closing brackets in the geometry.
//    010   26.01.21 Sean Flook         WI38232 Only allow the user to edit the geometry if they are allowed.
//    011   27.01.21 Sean Flook         WI38232 Do not allow ESU geometry to be edited.
//    012   29.01.21 Peter Bryden       WI38232 cleaned up code and handled save back to forms
//    013   03.02.21 Sean Flook         WI38232 Ensure the graphics is correctly updated.
//    014   04.02.21 Sean Flook         WI38232 Split the extra graphic into 2 layers (highlight and edit) to prevent editing of ESUs and Whole Road ASD records.
//                                              Also correctly determine if a multilinestring geometry is intersecting itself.
//    015   09.02.21 Sean Flook         WI39121 When changing the extent for the highlight or edit layers sometimes ESRI gets it wrong so add a fudge of zooming out a level.
//    016   09.02.21 Sean Flook         WI39121 Applied fix for whole road as well.
//    017   10.02.21 Sean Flook         WI38232 Change to prevent browser scrollbar from always appearing.
//    018   12.02.21 Sean Flook         WI38232 Tweaked the height.
//    019   15.02.21 Sean Flook         WI39149 Moved draw-line to top right of map.
//    020   15.02.21 Sean Flook         WI39149 Undo above change as it breaks the application.
//    021   16.02.21 Sean Flook         WI39149 Remove draw tool and fully use the sketch widget.
//    022   17.02.21 Sean Flook         WI39175 Tidy up the code and ensure the line stays selected when reshaping it.
//    023   17.02.21 Sean Flook         WI39170 Only create one instance of the sketch widget.
//    024   18.02.21 Sean Flook         WI39168 Increased height.
//    025   23.02.21 Sean Flook         WI39199 Display a progress circle when loading the map.
//    026   23.02.21 Sean Flook         WI39198 Changed the line colours as required.
//    027   24.02.21 Sean Flook         WI39199 Use the base mapping layer to stop the progress as it is the last to be created.
//    028   24.02.21 Sean Flook         WI39199 Prevent the map from being created twice.
//    029   03.03.21 Sean Flook         WI39183 Add the copyright text to the layers.
//    029   04.03.21 Peter Bryden               Update OS api key to Scottish API key
//    030   03.03.21 Sean Flook         WI39263 Do not zoom to the extent of an ESU.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useEffect, useState, useRef } from 'react';
import { loadModules } from 'esri-loader';
import Wkt from 'wicket';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
/* #endregion imports */

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  mapProgress: {
    position: 'absolute',
    top: '50%',
    left: '60%',
  },
}));


//const api_key = "sGpUw3RJKKC3J6tUAyn2jK2O20XvsKtA";   //AA Key
const api_key = "jY9fppNFZXHckZyatLTlCEd2a8h2eNM7";   //ScottishKey

const baseMappingLayerName = "basMappingLayer";
const backgroundGraphicLayerName = "backgroundGraphicLayer";
const highlightGraphicLayerName = "highlightGraphicLayerName";
const editGraphicLayerName = "editGraphicLayerName";

const defaultColour = [217, 95, 2]; // orange
const defaultValidColour = [117, 112, 179]; // purple
const defaultInvalidColour = [255, 0, 0]; // red
const defaultLineWidth = 5;
const defaultExtraLineWidth = 5;
const defaultStyle = "solid";       //solid, none, dash, dot, dash-dot, long-dash, long-dash-dot, long-dash-dot-dot, short-dash, short-dash-dot, short-dash-dot-dot, short-dot
const defaultEditStyle = "solid";

const AAEsriMapWithGraphic = ({ extragraphic, backgroundgraphic, isAsdEditable, handleCoordSearch, handleGeometryChange }) => {

  const classes = useStyles();
  const [mapObjs, setMapObjs] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const isEditable = isAsdEditable && (extragraphic && extragraphic.editLayer && extragraphic.editLayer.type === "PartASD");
  const validEditSymbol = useRef({ type: "simple-line", color: defaultValidColour, width: defaultExtraLineWidth, style: defaultEditStyle });
  const invalidEditSymbol = useRef({ type: "simple-line", color: defaultInvalidColour, width: defaultExtraLineWidth, style: defaultEditStyle });

  const mapRef = useRef();
  const edittingMap = useRef(false);

  const typeLineString = "LineString";
  const typeMultiLineString = "MultiLineString";

  function GetWktCoOrdinates(wktStr) {
    var wkt = new Wkt.Wkt();

    try { // Catch any malformed WKT strings
      wkt.read(wktStr);
    } catch (e1) {
      try {
        wkt.read(wktStr.replace('\n', '').replace('\r', '').replace('\t', ''));
      } catch (e2) {
        if (e2.name === 'WKTError') {
          alert('Wicket could not understand the WKT string you entered. Check that you have parentheses balanced, and try removing tabs and newline characters.');
          return [];
        }
      }
    }

    const wktObj = wkt.toJson();
    return wktObj.coordinates;
  }

  function GetWktDetails(wktStr) {
    var wkt = new Wkt.Wkt();

    try { // Catch any malformed WKT strings
      wkt.read(wktStr);
    } catch (e1) {
      try {
        wkt.read(wktStr.replace('\n', '').replace('\r', '').replace('\t', ''));
      } catch (e2) {
        if (e2.name === 'WKTError') {
          alert('Wicket could not understand the WKT string you entered. Check that you have parentheses balanced, and try removing tabs and newline characters.');
          return [];
        }
      }
    }

    const wktObj = wkt.toJson();

    return wktObj;
  }

  function GetLayerGraphicsAsWKT(graphicsOnLayer) {

    if (!graphicsOnLayer) return "";
    var wktConvertedPaths = [];

    let wktType = graphicsOnLayer.length > 1 ? typeMultiLineString : typeLineString;

    graphicsOnLayer.map((g) => {

      const geometryPaths = g.geometry ? g.geometry.paths : [];
      wktType = g.attributes && g.attributes.typeName ? g.attributes.typeName : wktType;

      geometryPaths.map((points) => {
        let pathString = [];
        points.map((p) => { pathString.push(`${p[0]} ${p[1]}`) });
        wktConvertedPaths.push(`(${pathString.join(', ')})`)
      });
    });

    if (wktType === typeMultiLineString)
      return `${wktType.toUpperCase()} (${wktConvertedPaths.join(", ")})`;
    else
      return `${wktType.toUpperCase()} ${wktConvertedPaths.join(", ")}`;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") { return; }

    setAlertOpen(false);
  }

  useEffect(() => {
    if (!mapObjs) {
      // Prevent this code from being run a second time whilst it is still being run the first time
      setMapObjs({ theMap: null, theView: null, theExtent: null, theSketch: null });

      // lazy load the required ArcGIS API for JavaScript modules and CSS
      loadModules(['esri/Map',
        'esri/views/MapView',
        'esri/layers/VectorTileLayer',
        'esri/config',
        "esri/Graphic",
        "esri/layers/GraphicsLayer",
        "esri/geometry/Polyline",
        "esri/geometry/geometryEngine",
        "esri/widgets/Sketch",
        "esri/widgets/ScaleBar"], { css: true })
        .then(([Map, MapView, VectorTileLayer, esriConfig, Graphic, GraphicsLayer, Polyline, geometryEngine, Sketch, ScaleBar]) => {

            const apiKey = api_key;
            const serviceUrl = 'https://api.os.uk/maps/vector/v1/vts';
            //'/resources/styles?key=' + apiKey

          esriConfig.request.interceptors.push({
            urls: serviceUrl,
            before: function (params) {
              if (!params.requestOptions.query) {
                params.requestOptions.query = {};
              }
              params.requestOptions.query.key = apiKey;
            }
          });

          const tileLayer = new VectorTileLayer({ url: serviceUrl, id: baseMappingLayerName, copyright: "Contains OS data © Crown copyright and database rights " + (new Date().getFullYear()) });

          const backgroundGraphicsLayer = new GraphicsLayer({ id: backgroundGraphicLayerName, copyright: "© Copyright Aligned Assets Ltd. 2020 - " + (new Date().getFullYear()) });
          const highlightGraphicsLayer = new GraphicsLayer({ id: highlightGraphicLayerName });
          const editGraphicsLayer = new GraphicsLayer({ id: editGraphicLayerName });

          validEditSymbol.current = {
            type: "simple-line",
            color: extragraphic && extragraphic.editLayer && extragraphic.editLayer.colour ? extragraphic.editLayer.colour : defaultValidColour,
            width: extragraphic && extragraphic.editLayer && extragraphic.editLayer.width ? extragraphic.editLayer.width : defaultExtraLineWidth,
            style: extragraphic && extragraphic.editLayer && extragraphic.editLayer.style ? extragraphic.editLayer.style : defaultEditStyle
          };

          invalidEditSymbol.current = {
            type: "simple-line",
            color: extragraphic && extragraphic.editLayer && extragraphic.editLayer.invalidColour ? extragraphic.editLayer.invalidColour : defaultInvalidColour,
            width: extragraphic && extragraphic.editLayer && extragraphic.editLayer.width ? extragraphic.editLayer.width : defaultExtraLineWidth,
            style: extragraphic && extragraphic.editLayer && extragraphic.editLayer.style ? extragraphic.editLayer.style : defaultEditStyle
          };

          var background_extent = null
          if (backgroundgraphic) {
            const polyline = new Polyline({
              type: "polyline",
              paths: [],
              spatialReference: { wkid: 27700 }
            });

            const simpleLineSymbol = {
              type: "simple-line",
              style: backgroundgraphic.style ? backgroundgraphic.style : defaultStyle,
              color: backgroundgraphic.colour ? backgroundgraphic.colour : defaultColour,
              width: backgroundgraphic.width ? backgroundgraphic.width : defaultLineWidth
            };

            if (Array.isArray(backgroundgraphic.paths)) {
              backgroundgraphic.paths.map((x) => {
                return polyline.addPath(GetWktCoOrdinates(x));
              });
            } else {
              polyline.addPath(GetWktCoOrdinates(backgroundgraphic.paths));
            }

            const polylineGraphic = new Graphic({
              geometry: polyline,
              symbol: simpleLineSymbol
            });

            background_extent = polyline ? polyline.extent : null;
            backgroundGraphicsLayer.add(polylineGraphic);
          }

          const map = new Map({ layers: [tileLayer, backgroundGraphicsLayer, highlightGraphicsLayer, editGraphicsLayer] });

          const view = new MapView({
            container: mapRef.current,
            map: map,
            extent: (background_extent ? background_extent : ({ xmin: 18000.0, ymin: 530000.0, xmax: 660000.0, ymax: 1300000.0, spatialReference: { wkid: 27700 } })),
            constraints:
            {
              minZoom: 2,
              maxZoom: 15,
              rotationEnabled: false
            }
          });

          if (background_extent && (view.extent.xmax < background_extent.xmax ||
            view.extent.xmin > background_extent.xmin ||
            view.extent.ymax < background_extent.ymax ||
            view.extent.ymin > background_extent.ymin)) {
            view.zoom = view.zoom - 1;
          }

          view.on("click", onViewClick);
          view.on("layerview-create", onViewLayerCreate);

          function onViewClick(event) {
            if (!document.getElementById("select-button").hidden || edittingMap.current) {
              return;
            }

            document.getElementById("select-button").hidden = false;

            view.hitTest(event).then(function (response) {
              let results = response.results;
              // Check if the new ASD graphic was clicked and pass
              // the graphic to sketch.update() with reshape tool.
              results.forEach(function (result) {
                if (result.graphic.layer === tileLayer) {
                  const searchCoords = result.mapPoint.x.toString() + ',' + result.mapPoint.y.toString();
                  handleCoordSearch(searchCoords);
                }
              });
            });
          }

          function onViewLayerCreate(event) {
            setLoading(event.layer.id !== baseMappingLayerName);
          }

          const sketch = new Sketch({
            id: "AAeditWidget",
            layer: editGraphicsLayer,
            view: view,
            creationMode: "update",
            availableCreateTools: ["polyline"],
            defaultCreateOptions: { hasZ: false },
            defaultUpdateOptions: { tool: "reshape", enableRotation: false, enableZ: false, multipleSelectionEnabled: false, toggleToolOnClick: false },
          });

          sketch.viewModel.polylineSymbol = validEditSymbol.current;

          sketch.on("create", onASDCreate);
          sketch.on(["update", "undo", "redo", "vertex-add", "vertex-remove", "cursor-update", "draw-complete"], onASDUpdate);
          sketch.on("delete", onASDDelete);

          function onASDCreate(event) {
            if (event.state === "start")
              editGraphicsLayer.graphics.removeAll();

            if (event.graphic.geometry.paths && (event.state === "completed" || (event.graphic.geometry.paths && event.toolEventInfo && event.toolEventInfo.type === "vertex-add"))) {
              // check if the polyline intersects itself.
              const intersectingSegment = getIntersectingSegment(event.graphic.geometry);

              setAlertOpen(!alertOpen && intersectingSegment)

              event.graphic.symbol = (intersectingSegment || event.graphic.symbol.style === invalidEditSymbol.current.color) ? invalidEditSymbol.current : validEditSymbol.current;

              handleGeometryChange(GetLayerGraphicsAsWKT([event.graphic]), (intersectingSegment || event.graphic.symbol.style === invalidEditSymbol.current.color));
            }
          }

          function onASDUpdate(event) {
            // get the graphic as it is being updated
            const graphic = event.graphics[0];

            // check if the graphic is intersecting itself
            const intersects = isSelfIntersecting(graphic.geometry);

            setAlertOpen(!alertOpen && intersects)

            // change the graphic symbol to valid or invalid symbol
            // depending if the line intersects with itself.
            graphic.symbol = (intersects || graphic.symbol.color === invalidEditSymbol.current.color) ? invalidEditSymbol.current : validEditSymbol.current;

            if (event.state === "complete" || event.type === "undo" || event.type === "redo" || (event.toolEventInfo && (event.toolEventInfo.type === "move-stop" || event.toolEventInfo.type === "reshape-stop" || event.toolEventInfo.type === "vertex-add" || event.toolEventInfo.type === "vertex-remove" || event.toolEventInfo.type === "scale-stop")))
              handleGeometryChange(GetLayerGraphicsAsWKT(editGraphicsLayer.graphics), (intersects || graphic.symbol.color === invalidEditSymbol.current.color));

            // graphic moving or reshaping has been completed or cancelled (distinguish with aborted property)
            // if the graphic is in an illegal state, call sketch's update method again
            // giving user a chance to correct the graphic.
            if (event.state === "complete" && (intersects || graphic.symbol.color === invalidEditSymbol.current.color))
              sketch.update([graphic], { tool: "reshape" });
          }

          function onASDDelete(event) {
            event.graphics.forEach(function (graphic) {
              console.log("deleted", graphic)
            });
            handleGeometryChange(GetLayerGraphicsAsWKT(editGraphicsLayer.graphics), false);
          }

          // function that checks if the line intersects itself
          function isSelfIntersecting(polyline) {
            // if we only have a single line with only 2 nodes it cannot cross itself
            if (polyline.paths.length === 1 && polyline.paths[0].length < 3) {
              return false;
            }

            const line = polyline.clone();
            let lineIntersects = false;

            for (var z = 0; z < polyline.paths.length; z++) {
              if (polyline.paths[z] && polyline.paths[z].length && polyline.paths[z].length > 0) {
                for (var x = 0; x < polyline.paths[z].length; x++) {
                  //get the next segment from the polyline that is being drawn
                  const lastSegment = getNextSegment(polyline, z, x);
                  line.removePoint(z, line.paths[z].length - 1);

                  // returns true if the line intersects itself, false otherwise
                  lineIntersects = geometryEngine.crosses(lastSegment, line);

                  if (lineIntersects) break;
                }
              }

              if (lineIntersects) break;
            }

            return lineIntersects;
          }

          // Get the next segment of the polyline that is being edited
          function getNextSegment(polyline, pathIdx, pointIdx) {
            const line = polyline.clone();
            for (var z = 1; z < pointIdx; z++) {
              line.removePoint(pathIdx, line.paths[pathIdx].length - 1);
            }
            const lastXYPoint = line.removePoint(pathIdx, line.paths[pathIdx].length - 1);
            const existingLineFinalPoint = line.getPoint(
              pathIdx,
              line.paths[pathIdx].length - 1
            );

            return {
              type: "polyline",
              spatialReference: view.spatialReference,
              hasZ: false,
              paths: [
                [
                  [existingLineFinalPoint.x, existingLineFinalPoint.y],
                  [lastXYPoint.x, lastXYPoint.y]
                ]
              ]
            };
          }

          // Checks if the line intersects itself. If yes, change the last
          // segment's symbol giving a visual feedback to the user.
          function getIntersectingSegment(polyline) {
            if (isSelfIntersecting(polyline)) {
              return new Graphic({
                geometry: getLastSegment(polyline, 0, polyline.paths[0].length),
                symbol: invalidEditSymbol.current
              });
            }
            return null;
          }

          // Get the last segment of the polyline that is being drawn
          function getLastSegment(polyline) {
            const line = polyline.clone();
            const lastXYPoint = line.removePoint(0, line.paths[0].length - 1);
            const existingLineFinalPoint = line.getPoint(
              0,
              line.paths[0].length - 1
            );

            return {
              type: "polyline",
              spatialReference: view.spatialReference,
              hasZ: false,
              paths: [
                [
                  [existingLineFinalPoint.x, existingLineFinalPoint.y],
                  [lastXYPoint.x, lastXYPoint.y]
                ]
              ]
            };
          }

          var scaleBar = new ScaleBar({
            view: view,
            unit: "dual" // The scale bar displays both metric and non-metric units.
          });

          view.ui.add(scaleBar, {
            position: "bottom-left"
          });

          view.ui.add("select-button", "top-left");

          document.getElementById("select-button").onclick = function () {
            document.getElementById("select-button").hidden = true;
          };

          const extent = background_extent;
          setMapObjs({ theMap: map, theView: view, theExtent: extent, theSketch: sketch });
        });
    }

    if (mapObjs && mapObjs.theMap) {

      const mapLayers = mapObjs.theMap.layers.items;

      if (extragraphic && extragraphic.highlightLayer && extragraphic.highlightLayer.paths !== null) {
        loadModules([
          'esri/Graphic',
          'esri/geometry/Polyline'], { css: true })
          .then(([Graphic, Polyline]) => {

            const highlightSymbol = {
              type: "simple-line",
              color: extragraphic.highlightLayer.colour ? extragraphic.highlightLayer.colour : defaultColour,
              width: extragraphic.highlightLayer.width ? extragraphic.highlightLayer.width : defaultLineWidth,
              style: extragraphic.highlightLayer.style ? extragraphic.highlightLayer.style : defaultStyle
            };

            function CreateHighlightGraphic(graphicType, graphicPaths) {
              const highlightPolyline = new Polyline({
                type: "polyline",
                paths: graphicPaths,
                spatialReference: { wkid: 27700 }
              });

              const highlightPolylineGraphic = new Graphic({
                attributes: { typeName: graphicType },
                geometry: highlightPolyline,
                symbol: highlightSymbol
              });

              return highlightPolylineGraphic;
            }

            let highlightPolylineGraphics = [];
            if (Array.isArray(extragraphic.highlightLayer.paths)) {
              let graphicPaths = [];
              extragraphic.highlightLayer.paths.map((x) => {
                const wktDetails = GetWktDetails(x);
                return graphicPaths.push(wktDetails.coordinates);
              });
              if (graphicPaths.length > 1)
                highlightPolylineGraphics.push(CreateHighlightGraphic(typeMultiLineString, graphicPaths));
              else
                highlightPolylineGraphics.push(CreateHighlightGraphic(typeLineString, graphicPaths));
            }
            else {
              const wktDetails = GetWktDetails(extragraphic.highlightLayer.paths);
              const createdGraphic = CreateHighlightGraphic(wktDetails.type, wktDetails.coordinates);
              highlightPolylineGraphics.push(createdGraphic);
            }

            for (var z = 0; z < mapLayers.length; z++) {
              if (mapLayers[z] && mapLayers[z].id === highlightGraphicLayerName) {
                mapLayers[z].graphics.removeAll();

                highlightPolylineGraphics.map((p) => {
                  mapLayers[z].graphics.add(p);
                });

                var sketchWidget = mapObjs.theView.ui.find("AAeditWidget");
                if (sketchWidget) mapObjs.theView.ui.remove(sketchWidget);

                // If we have an highlight layer try and zoom to the geometry
                if (highlightPolylineGraphics && highlightPolylineGraphics.length > 0 && extragraphic.highlightLayer.type !== "ESU") {
                  mapObjs.theView.extent = highlightPolylineGraphics[0].geometry.extent;

                  // Check to see if esri have got the extent wrong, if they have try and fudge it by zooming out a level
                  if (mapObjs.theView.extent.xmax < highlightPolylineGraphics[0].geometry.extent.xmax ||
                    mapObjs.theView.extent.xmin > highlightPolylineGraphics[0].geometry.extent.xmin ||
                    mapObjs.theView.extent.ymax < highlightPolylineGraphics[0].geometry.extent.ymax ||
                    mapObjs.theView.extent.ymin > highlightPolylineGraphics[0].geometry.extent.ymin) {
                    mapObjs.theView.zoom = mapObjs.theView.zoom - 1;
                  }
                } else {
                  mapObjs.theView.extent = mapObjs.theExtent;

                  // Check to see if esri have got the extent wrong, if they have try and fudge it by zooming out a level
                  if (mapObjs.theView.extent.xmax < mapObjs.theExtent.xmax ||
                    mapObjs.theView.extent.xmin > mapObjs.theExtent.xmin ||
                    mapObjs.theView.extent.ymax < mapObjs.theExtent.ymax ||
                    mapObjs.theView.extent.ymin > mapObjs.theExtent.ymin) {
                    mapObjs.theView.zoom = mapObjs.theView.zoom - 1;
                  }
                }
              }
            }
          }).catch((err) => console.error(err));
      }
      else {
        for (var z = 0; z < mapLayers.length; z++) {
          if (mapLayers[z] && (mapLayers[z].id === highlightGraphicLayerName)) {
            mapLayers[z].graphics.removeAll();
          }
        }

        if (mapObjs.theView && mapObjs.theExtent) {
          mapObjs.theView.extent = mapObjs.theExtent;

          // Check to see if esri have got the extent wrong, if they have try and fudge it by zooming out a level
          if (mapObjs.theView.extent.xmax < mapObjs.theExtent.xmax ||
            mapObjs.theView.extent.xmin > mapObjs.theExtent.xmin ||
            mapObjs.theView.extent.ymax < mapObjs.theExtent.ymax ||
            mapObjs.theView.extent.ymin > mapObjs.theExtent.ymin) {
            mapObjs.theView.zoom = mapObjs.theView.zoom - 1;
          }
        }
      }

      if (extragraphic && extragraphic.editLayer && extragraphic.editLayer.paths !== null) {
        loadModules([
          'esri/Graphic',
          'esri/geometry/Polyline',
          "esri/views/draw/Draw"], { css: true })
          .then(([Graphic, Polyline]) => {

            validEditSymbol.current = {
              type: "simple-line",
              color: extragraphic.editLayer.colour ? extragraphic.editLayer.colour : defaultValidColour,
              width: extragraphic.editLayer.width ? extragraphic.editLayer.width : defaultExtraLineWidth,
              style: extragraphic.editLayer.style ? extragraphic.editLayer.style : defaultEditStyle
            };

            function CreateEditGraphic(graphicType, graphicPaths) {
              const editPolyline = new Polyline({
                type: "polyline",
                paths: graphicPaths,
                spatialReference: { wkid: 27700 }
              });

              const editPolylineGraphic = new Graphic({
                attributes: { typeName: graphicType },
                geometry: editPolyline,
                symbol: validEditSymbol.current
              });

              return editPolylineGraphic;
            }

            let editPolylineGraphics = [];
            if (Array.isArray(extragraphic.editLayer.paths)) {
              let graphicPaths = [];
              extragraphic.editLayer.paths.map((x) => {
                const wktDetails = GetWktDetails(x);
                return graphicPaths.push(wktDetails.coordinates);
              });
              if (graphicPaths.length > 1) {
                editPolylineGraphics.push(CreateEditGraphic(typeMultiLineString, graphicPaths));
              }
              else {
                editPolylineGraphics.push(CreateEditGraphic(typeLineString, graphicPaths));
              }
            }
            else {
              const wktDetails = GetWktDetails(extragraphic.editLayer.paths);
              const createdGraphic = CreateEditGraphic(wktDetails.type, wktDetails.coordinates);
              editPolylineGraphics.push(createdGraphic);
            }

            handleGeometryChange(GetLayerGraphicsAsWKT(editPolylineGraphics), false);

            let editLayer;

            for (var z = 0; z < mapLayers.length; z++) {
              if (mapLayers[z] && mapLayers[z].id === editGraphicLayerName) {
                mapLayers[z].graphics.removeAll();

                editPolylineGraphics.map((p) => {
                  mapLayers[z].graphics.add(p);
                });

                editLayer = mapLayers[z];

                var sketchWidget = mapObjs.theView.ui.find("AAeditWidget");
                if (sketchWidget) mapObjs.theView.ui.remove(sketchWidget);

                // If we have an edit layer try and zoom to the geometry
                if (editPolylineGraphics && editPolylineGraphics.length > 0) {
                  mapObjs.theView.extent = editPolylineGraphics[0].geometry.extent;

                  // Check to see if esri have got the extent wrong, if they have try and fudge it by zooming out a level
                  if (mapObjs.theView.extent.xmax < editPolylineGraphics[0].geometry.extent.xmax ||
                    mapObjs.theView.extent.xmin > editPolylineGraphics[0].geometry.extent.xmin ||
                    mapObjs.theView.extent.ymax < editPolylineGraphics[0].geometry.extent.ymax ||
                    mapObjs.theView.extent.ymin > editPolylineGraphics[0].geometry.extent.ymin) {
                    mapObjs.theView.zoom = mapObjs.theView.zoom - 1;
                  }
                } else {
                  mapObjs.theView.extent = mapObjs.theExtent;

                  // Check to see if esri have got the extent wrong, if they have try and fudge it by zooming out a level
                  if (mapObjs.theView.extent.xmax < mapObjs.theExtent.xmax ||
                    mapObjs.theView.extent.xmin > mapObjs.theExtent.xmin ||
                    mapObjs.theView.extent.ymax < mapObjs.theExtent.ymax ||
                    mapObjs.theView.extent.ymin > mapObjs.theExtent.ymin) {
                    mapObjs.theView.zoom = mapObjs.theView.zoom - 1;
                  }
                }

                if (isEditable) {
                  // Whilst editing do not allow the user to select a new street
                  document.getElementById("select-button").hidden = true;
                  edittingMap.current = true;

                  mapObjs.theView.ui.add(mapObjs.theSketch, "top-right");
                } else {
                  if (mapObjs.theView) {
                    var sketchWidget = mapObjs.theView.ui.find("AAeditWidget");
                    if (sketchWidget) mapObjs.theView.ui.remove(sketchWidget);
                  }

                  document.getElementById("select-button").hidden = false;
                }
              }
            }
          }).catch((err) => console.error(err));
      }
      else {
        for (var z = 0; z < mapLayers.length; z++) {
          if (mapLayers[z] && (mapLayers[z].id === editGraphicLayerName)) {
            mapLayers[z].graphics.removeAll();
          }
        }

        if (mapObjs.theView && mapObjs.theExtent) {
          mapObjs.theView.extent = mapObjs.theExtent;

          // Check to see if esri have got the extent wrong, if they have try and fudge it by zooming out a level
          if (mapObjs.theView.extent.xmax < mapObjs.theExtent.xmax ||
            mapObjs.theView.extent.xmin > mapObjs.theExtent.xmin ||
            mapObjs.theView.extent.ymax < mapObjs.theExtent.ymax ||
            mapObjs.theView.extent.ymin > mapObjs.theExtent.ymin) {
            mapObjs.theView.zoom = mapObjs.theView.zoom - 1;
          }
        }

        if (mapObjs.theView) {
          var sketchWidget = mapObjs.theView.ui.find("AAeditWidget");
          if (sketchWidget) mapObjs.theView.ui.remove(sketchWidget);
        }

        document.getElementById("select-button").hidden = false;
      }
    }

    return () => {
      if (mapObjs && mapObjs.theView) {
        // destroy the map view
        //mapObjs.theView.destroy();
      }
    };
  }, [extragraphic, backgroundgraphic]);


  return <React.Fragment>
    <div id="progress-indicator">
      {loading && <CircularProgress className={classes.mapProgress} />}
    </div>
    <div className="webmap" ref={mapRef}>
      <div
        id="select-button"
        class="esri-widget esri-widget--button esri-interactive"
        title="Select a Street"
      >
        <span class="esri-icon-cursor-filled" />
      </div>
      <div id="intersection-alert">
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert severity="error" variant="filled" onClose={handleClose}>
            The line intersects itself!
        </Alert>
        </Snackbar>
      </div>
    </div>
  </React.Fragment>;
};

export default AAEsriMapWithGraphic; 