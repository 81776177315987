/**************************************************************************************************
//
//  Description: Display list of ASD items
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   23.03.21 Peter Bryden        WI39243 refactored Item from AAAssociatedStreetDataList to hold state context for special designations
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    002   27.05.21 Peter Bryden        WI39324 add checkboxes so asds can be selected in maintenance
//    003   09.06.21 Paula Dormer        WI39308 Only highlight open ASD on closed streets.
//    004   18.06.21 Peter Bryden        WI39514 Pass through property lastedited
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';

import Collapse from '@material-ui/core/Collapse';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { brown, yellow, pink, green } from "@material-ui/core/colors";

import AAAssociatedStreetDataListItem from './AAAssociatedStreetDataListItem'
import { useAAAssociatedStreetDataListState, useAAAssociatedStreetDataUpdateState } from './AAAssociatedStreetDataListContext';

/* #endregion imports */

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 450,
    backgroundColor: theme.palette.background.paper,
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nested_1: {
    paddingLeft: theme.spacing(10),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  brown: {
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
  },
  yellow: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
  },

}));

const AAAssociatedStreetDataTieredListItem = ({ title, type, colour, data, editable, itemSelect, itemAdd, tiers, handleChecked, highlightItem, lastEdited }) => {
  const classes = useStyles();
  const isEditable = editable;
  
  const getOpen = useAAAssociatedStreetDataListState();
  const toggleOpen = useAAAssociatedStreetDataUpdateState();

  const [open, setOpen] = useState(getOpen ? getOpen(title) : true)

  const [checkedAll, setCheckedAll] = useState();
  const [forceChecked, setForceChecked] = useState(null);

  const getCheckedData = () => {
    return data ? data.map((d, i) => {
      return { ...d, checked: checkedAll | forceChecked }
    }) : null;
	}

  const [asdData, setAsdData] = useState(data);

  const handleOpenClick = () => {
    setOpen(!open);
    if(toggleOpen)
      toggleOpen(title);
  };

  function ShowCheckBoxAll() {

    return handleChecked ? <ListItemSecondaryAction>
      <Checkbox
        edge="end"
        onChange={(e) => handleAllChecked(e.target.checked)}
        checked={checkedAll}
        inputProps={{ 'aria-labelledby': title }}
      />
    </ListItemSecondaryAction>
      : "";
  }

  const handleAllChecked = (isChecked) => {

    if (handleChecked) {
      const updatedData = data ? data.map((d) => {
        return { ...d, checked: isChecked };
      }) : [];
      setForceChecked(isChecked);
      setCheckedAll(isChecked);
      setAsdData(updatedData);
    }
  }

  function handleTierChecked(tier_id, checkedItems) {
    if (checkedItems) {
      const filteredData = asdData.filter(x => x.tier_id !== tier_id);
      const tieredData = asdData.filter(x => x.tier_id === tier_id);

      const updatedData = tieredData ? tieredData.map((d) => {
        const newChecked = (checkedItems.indexOf(d.pkId) >= 0);
        return { ...d, checked: newChecked };
      }) : [];

      const newAsdData = [...filteredData, ...updatedData];
      const newCheckAll = !(newAsdData.findIndex((x) => !x.checked) >= 0);

      setForceChecked(null);
      setCheckedAll(newCheckAll);
      setAsdData(newAsdData);
    }
  }

  useEffect(() => {
    const checkedData = asdData ? asdData.filter(x => x.checked).map((i) => {
      return i.pkId;
    }) : [];

    if (handleChecked)
      handleChecked(checkedData);
  }, [asdData])

  const avatarColours = [{ "colour": "brown", "classesName": classes.brown },
  { "colour": "yellow", "classesName": classes.yellow },
  { "colour": "pink", "classesName": classes.pink }];

  avatarColours.find((x) => x.colour === colour);

  function showTieredList(tierId) {

    const tierData = asdData.filter(x => x.tier_id === tierId);

    return (
      <List component="div" disablePadding key={`${title}_${tierId}`} className="asd-ListItem">
        <AAAssociatedStreetDataListItem title={`${tiers.find(g => g.id === tierId).name}`} colour="yellow"
          itemSelect={(pkId, dataIdx, dataLength, wholeRoad) => itemSelect(pkId, dataIdx, dataLength, wholeRoad)}
          data={tierData}
          handleChecked={handleChecked ? (checkedItems) => handleTierChecked(tierId, checkedItems) : null}
          editable={isEditable}
          forceChecked={forceChecked}
          highlightItem={highlightItem}
          lastEdited={lastEdited}
        />
      </List>
    );
	}

  return (
    <React.Fragment>
      <ListItem button className="asd-ListItem" >
        {open ? <ExpandLess onClick={handleOpenClick} /> : <ExpandMore onClick={handleOpenClick} />}
        <ListItemIcon onClick={handleOpenClick}>
          <ReportProblemOutlinedIcon className={classes.yellow} />
        </ListItemIcon>
        <ListItemText
          primary={<Typography className="form__component" variant="subtitle1" onClick={handleOpenClick}><strong>{title}</strong></Typography>}
          secondary={<React.Fragment>
            <Typography variant="subtitle2" onClick={handleOpenClick}>{type}</Typography>
            {isEditable ?
              <IconButton onClick={() => itemAdd(asdData ? asdData.length : 0)}  size="small">
                <Tooltip title="Add" arrow placement="right">
                  <AddCircleOutlineIcon style={{ color: green[500] }} />
                </Tooltip>
              </IconButton>
              : null}
          </React.Fragment>} />
        <ListItemAvatar onClick={handleOpenClick}>
          <Avatar className={classes.small}>
            <Typography variant="caption">
              {`${asdData ? asdData.length : 0} `}
            </Typography>
          </Avatar>
        </ListItemAvatar>
        <ShowCheckBoxAll />

      </ListItem>
      <Collapse in={open} timeout="auto">
        {
          tiers && tiers.map((rc) => 
            showTieredList(rc.id)
          )}
      </Collapse>
    </React.Fragment>
  );
};
 
export default AAAssociatedStreetDataTieredListItem;
