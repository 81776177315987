/**************************************************************************************************
//
//  Description: Maintenance page 
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.1.0 changes
//    001   27.05.21 Peter Bryden                Initial Revision.
//    002   01.06.21 Peter Bryden        WI39324 Change list component to table component so not to use kendo licening 
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import { MaintenanceStreetTableView } from "../components/maintenancepage/MaintenanceStreetTableView"

import { useParams,Redirect } from "react-router-dom";



import {
  GetStreetFromUSRN,
  GetSearchStreetURL,
  GetCanOperatorEditASDDataURL,
  GetClosedStreetsWithASDURL
} from '../configuration/AAConfig'


import authService from '../components/api-authorization/AuthorizeService';

import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import history from "../history";

const apifetch = async (url, dataIfAborted, signal) => {
  try {
    const response = await fetch(url, { signal })

    const data = await response.json();
    return data;
  } catch (err) {
    if (err.name === "AbortError") {
      console.log("Request Aborted ");
      return dataIfAborted;
    }
    return err;
  }
};


/* #endregion imports */

const MaintenancePage = () => {

  const urlParameters = useParams();

  const [loading, setLoading] = useState(false);

  const [apiURLs, setApiURLs] = useState(null);

  const [data, setData] = useState();
  const [closedUSRN, setClosedUSRN] = useState(urlParameters ? urlParameters.maintenanceUSRN : null);

  const [operatorId, setOperatorId] = React.useState();

  const getOperatorId = () => { return operatorId; }

  /* HOOKS*/
  useEffect(() => {

    async function populateOperator()  {
      const [authenticated] = await Promise.all([authService.isAuthenticated()])

      if (authenticated && operatorId == null) {
        const token = await authService.getAccessToken();
        const response = await fetch('user', {
          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(res => (res.ok ? res : Promise.reject(res)))
          .then(res => res.json())
          .then(
            (result) => {
              console.log("populateOperator", result);
              if (result)
                setOperatorId(result.operatorid);
            },
            (error) => {
              console.log("Get operator Error", error);
              if (error.status === 401) {
                const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
                history.push(logoutPath);
              }
            }
          );
      }
    };

    const _subscription = authService.subscribe(() => populateOperator());

    async function SetUpApi() {
      if (apiURLs == null) {
        const canEditUrl = await GetCanOperatorEditASDDataURL();
        const usrnUrl = await GetStreetFromUSRN();
        const searchUrl = await GetSearchStreetURL();
        const closedstreetsURL = await GetClosedStreetsWithASDURL();

        setApiURLs({ canEditASD: canEditUrl, usrnApiDetails: usrnUrl, SearchApiDetails: searchUrl, ClosedStreetsWithASD: closedstreetsURL });
      }
    }

    async function SetUpClosedStreetData() {
      if (!data) {

        if (apiURLs && operatorId) {
          const closedStreets = apiURLs.ClosedStreetsWithASD;
          setLoading(true);
          fetch(`${closedStreets.url}\\${getOperatorId()}`)
            .then(res => (res.ok ? res : Promise.reject(res)))
            .then(res => res.json())
            .then(
              (result) => {
                setData(result);
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                console.log("closed streets fetch error = ", error);
              }
            ).then(() => {
              setLoading(false);
            });
        }
      }
    }

    if (operatorId == null)
      populateOperator();

    if (apiURLs === null)
      SetUpApi();

    SetUpClosedStreetData();

    return () => {
      authService.unsubscribe(_subscription);
    };

  }, [operatorId, apiURLs]);

  const handleClick = (usrn) => {
    setClosedUSRN(usrn);
  }

  function ShowMaintenanceList() {
    return (
      <div className="maintenance-page"  >

        <Grid container justify="flex-start" spacing={1}>
          <Grid item>
            <Grid container justify="flex-start">
              <Grid item>
                <Typography variant="h6" display="flex-start">Closed Streets With ASD</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-start">
              <Grid item xs={8}>
                <MaintenanceStreetTableView streets={data} handleSelection={handleClick} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  function ShowASDList() {
    const redirection = `maintenance/${closedUSRN}`;
    return (
      <Redirect to={redirection} />
      );
  }

  if (!loading) {
    if (isNaN(closedUSRN)) {
      return ShowMaintenanceList();
    }
    else
    {
      return ShowASDList();
    }
  }
  else
    return ( "Loading Maintenance Page" );
};

export default MaintenancePage;
