/**************************************************************************************************
//
//  Description:  master reducer for Redux store, combines reducers for slices
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   13.01.21 Joel Benford        WI39064 Initial Revision.
//    002   26.01.21 Joel Benford        WI39069 Add UI reducer
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { combineReducers } from "redux";
import userReducer from "./user/userReducer";
import configReducer from "./config/configReducer";
import entitiesReducer from "./entities/entitiesReducer";
import uiReducer from "./ui/uiReducer"; //JB WI39069
// #endregion imports

 //JB WI39069++
export default combineReducers({
  config: configReducer,
  entities: entitiesReducer,
  ui: uiReducer,
  user: userReducer
});
 //JB WI39069--
