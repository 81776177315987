//#region header */
/**************************************************************************************************
//
//  Description: GAAAssociatedStreetDataListContext
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   21.03.21 Peter Bryden        WI39243 Add context to hold state of treelist open states
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    002   18.06.21 Peter Bryden       WI39514 Added last used context
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */
import React, { useState, useContext, createContext, useEffect } from "react";


const ListStateContext = createContext();
const ListStateUpdateContext = createContext();

const LastASDUsedContext = createContext();
const LastASDUsedUpdateContext = createContext();

export function useAAAssociatedStreetDataListState() { return useContext(ListStateContext); }
export function useAAAssociatedStreetDataUpdateState() { return useContext(ListStateUpdateContext); }

export function useAAAssociatedStreetDataLastUsedState() { return useContext(LastASDUsedContext); }
export function useAAAssociatedStreetDataLastUsedUpdateState() { return useContext(LastASDUsedUpdateContext); }


export function AAAssociatedStreetDataListContextProvider({ children }) {

  const [open, setOpen] = useState({});

  function toggleOpen(name)
  {
    let newOpen = open;
    newOpen[name] = !open[name];
    setOpen(newOpen);
  }

  function getOpen(name) {
    if (open[name] === undefined) {
      let newOpen = open;
      newOpen[name] = true;
      setOpen(newOpen);
		}

    return open[name];
}

  useEffect(() => {
  }, [open]);

  return (
    <ListStateContext.Provider value={getOpen}>
      <ListStateUpdateContext.Provider value={toggleOpen }>
      {children}
      </ListStateUpdateContext.Provider>
    </ListStateContext.Provider>
  );
}

export function AAAssociatedStreetDataLastUsedContextProvider({ children }) {

  const [lastUsed, setLastUsed] = useState({});
  const [typeUsed, setTypeUsed] = useState({});

  function setLastUsedValue(theLastUsed, theTypeUsed) {
    setLastUsed(theLastUsed);
    setTypeUsed(theTypeUsed);
  }

  function getLastUsedValue() {
    return lastUsed ? { "pkid": lastUsed, "type": typeUsed } : null;
  }

  useEffect(() => {
  }, []);

  return (
    <LastASDUsedContext.Provider value={getLastUsedValue}>
      <LastASDUsedUpdateContext.Provider value={setLastUsedValue}>
        {children}
      </LastASDUsedUpdateContext.Provider>
    </LastASDUsedContext.Provider>
  );
}





