import React, {useState, useRef, useContext, createContext } from "react";
import ConfirmEditLossDialog from "./../dialogs/ConfirmEditLossDialog";

const ConfirmationServiceContext = createContext();

export const useConfirmation = () => useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }) =>
{
  const [confirmationState,setConfirmationState] = useState(null);

  const awaitingPromiseRef = useRef();

  const openConfirmation = (options) => {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleCancelMoveAway = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleAllowDispose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <React.Fragment>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmEditLossDialog
        isOpen={confirmationState}
        message={"Are you sure you want to lose this edit"}
        handleReturnClick={handleCancelMoveAway}
        handleDisposeClick={handleAllowDispose}
        //{...confirmationState}
      />
      </React.Fragment>
  );
};
