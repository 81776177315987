/* #region header */
/**************************************************************************************************
//
//  Description: ASD_AuthorityDropDown component for the authority drop down, refactored from asd forms
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   27.01.21 Peter Bryden               Initial Revision.
//    002   08.02.21 Sean Flook         WI38232 Set requiredField.
//    003   10.03.21 Sean Flook         WI39198 Do not explicitly set the width of the avatar.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState } from "react";
import AAAvatarSelect from "../AAAvatarSelect";

/* #endregion imports */

const ASD_AuthorityDropDown = ({ authority_data, selectedValue, changeHandler, placeholder_text, dataRequired }) => {

  const dataKey = "swaOrgRef";
  const dataText = "swaOrgNameText";

  const [data, setData] = useState(authority_data);
  const [selectedKey, setSelectedKey] = useState(selectedValue);

  const handleChange = (event) => { if (changeHandler != null) changeHandler(event.value[dataKey]); }

  return (
    <AAAvatarSelect
      id="Authority"
      data={data}
      avatarField={dataKey}
      textField={dataText}
      keyField={dataKey}
      placeholder={placeholder_text}
      selectedKey={selectedKey}
      colour="greyWide"
      requiredField={dataRequired}
      onChange={handleChange}
    />
  );
}

export default ASD_AuthorityDropDown;