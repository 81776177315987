/**************************************************************************************************
//
//  Description: Navigation sidebar
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useEffect, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
/* #endregion imports */

const AAUserAvatar = (props) => {

  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [userName, setUserName] = React.useState("");

  const handleItemClick = props.handleClick;

  useEffect(() => {

    const _subscription = authService.subscribe(() => populateState());
    populateState();

    return () => { authService.unsubscribe(_subscription); }

  }, []);

  const authenticatedView = (userName, profilePath, logoutPath) => {
    return (<Fragment>

      <Grid item xs={12}>
        <IconButton aria-label="profile" onClick={() => { handleItemClick(profilePath) }}>
          <Tooltip title={userName} arrow placement="right">
            <AccountCircleIcon
              color="primary"
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <IconButton aria-label="logout" onClick={() => { handleItemClick(logoutPath) }}>
          <Tooltip title="Logout" arrow placement="right">
            <ExitToAppIcon
              color="primary"
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>
    </Fragment>);
  };

  const anonymousView = (loginPath) => {
    return (<Fragment>
      <Grid item xs={12}>
        <IconButton aria-label="profile" onClick={() => { handleItemClick(loginPath) }}>
          <Tooltip title="Login" arrow placement="right">
            <AccountCircleIcon
              color="primary"
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>
    </Fragment>);
  };


  const populateState = async () => {
    const [authenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    setAuthenticated(authenticated);
    setUserName(user && user.name);
  }

  if (!isAuthenticated) {
    const loginPath = `${ApplicationPaths.Login}`;
    return anonymousView( loginPath);
  } else {
    const profilePath = `${ApplicationPaths.Profile}`;
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
    return authenticatedView(userName, profilePath, logoutPath);
  }
}

export default AAUserAvatar;