/**************************************************************************************************
//
//  Description: form for data related to ASD details
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.11.20 Peter Bryden               Initial Revision.
//    002   15.01.21 Sean Flook         WI39041 Removed start and end coordinates as not required and changed Add icon to a save icon.
//    003   15.01.21 Sean Flook         WI39041 Changed size of grid to accommodate the icons.
//    004   18.01.21 Sean Flook         WI39041 Handle the onChange event for the DatePicker so that the control can update the underlying data.
//    005   21.01.21 Sean Flook         WI38232 Changes required for saving the data.
//    006   21.01.21 Sean Flook         WI38232 Correctly get text control data.
//    007   21.01.21 Sean Flook         WI38232 Use the correct change_type when updating the record.
//    008   22.01.21 Sean Flook         WI38232 Bind the specific location data to the control.
//    009   26.01.21 Sean Flook         WI38232 Changes required to only allow editing of the data if the user has permission to.
//    010   29.01.21 Peter Bryden       WI38232 cleaned up code and handled save back to forms
//    011   01.02.21 Sean Flook         WI38232 Corrected labels.
//    012   02.02.21 Sean Flook         WI38232 Only allow editing of geometry if part of road selected.
//    013   02.02.21 Sean Flook         WI38232 Fixed onChange event for TextField components.
//    014   04.02.21 Sean Flook         WI38232 Set/Clear the end date when changing the state.
//    015   08.02.21 Sean Flook         WI38232 Display validation errors.
//    016   09.02.21 Sean Flook         WI38232 Changed label components to Typography components.
//    017   10.02.21 Sean Flook         WI38232 Display the number of characters used in the text fields.
//    018   15.02.21 Sean Flook         WI39147 Removed amp; from description helperText.
//    019   15.02.21 Sean Flook         WI39148 Correctly set isEdittable and dataRequired for end date.
//    020   10.02.21 Sean Flook         WI38232 Changed div to React.Fragment so that the icons appear on the same line.
//    021   26.02.21 Peter Bryden       WI39153 Add confirmation dialog if trying to leave during edit
//    022   03.03.21 Sean Flook         WI39198 Use Typography component to display read only dates.
//    023   09.03.21 Sean Flook         WI39166 Added tooltip to icons.
//    024   10.03.21 Sean Flook         WI39198 Updated the return icon.
//    025   08.04.21 Peter Bryden       WI39324 Added in functionality to "simulate" copy of existing data
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useState, useEffect } from "react";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Typography, Grid, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import TextField from '@material-ui/core/TextField';

import ASD_ShortDatePicker from "../../components/AssociatedStreetData/ASD_ShortDatePicker";
import ASD_CustodianDropDown from "../../components/AssociatedStreetData/ASD_CustodianDropDown";
import ASD_AuthorityDropDown from "../../components/AssociatedStreetData/ASD_AuthorityDropDown";
import ASD_SpecialDesignationTypeDropDown from "../../components/AssociatedStreetData/ASD_SpecialDesignationTypeDropDown";
import AAErrorDisplay from "../../components/AAErrorDisplay";

import { getToday } from "../../utils/datetimeUtils";
import { orange } from "@material-ui/core/colors";


/* #endregion imports */

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    formrow: {
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        display: "flex",
        justifyContent: "flex-start"
  },
  orange: {
    color: orange[500]
  }

}));

const SpecialDesignationForm = ({ Lookup_OSGSpecialDesignationCodes, Lookup_OSGSwaOrgRefs, data, editable, onReturnClick, onSaveClick, onDeleteClick, onWholeRoadChange, dataIdx, dataLen, logEdit }) => {

  const classes = useStyles();

  const [title, setTitle] = useState(`Special Designation ${dataIdx} of ${dataLen}`);
  const [formData, setFormData] = useState(data);
  const isEditable = editable;
  const [asdState, setAsdState] = useState(formData.state);
  const [appliesToWholeRoad, setAppliesToWholeRoad] = useState(formData.wholeRoad);
  const [startDate, setStartDate] = useState(formData.startDate);
  const [endDate, setEndDate] = useState(formData.endDate);
  const [custodianCode, setCustodianCode] = useState(formData.custodianCode);
  const [authorityCode, setAuthorityCode] = useState(formData.authorityCode);
  const [specialDesignationType, setSpecialDesignationType] = useState(formData.specialDesignationCode);
  const [description, setDescription] = useState(formData.description);
  const [specificLocation, setSpecificLocation] = useState(formData.specificLocation);

  const [specialDesignationTypeError, setSpecialDesignationTypeError] = useState(null);
  const [custodianCodeError, setCustodianCodeError] = useState(null);
  const [authorityCodeError, setAuthorityCodeError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [specificLocationError, setSpecificLocationError] = useState(null);
  const [wholeRoadError, setWholeRoadError] = useState(null);
  const [dbError, setDbError] = useState(null);
  const [haveError, setHaveError] = useState(false);

  const handleStartDateChangeEvent = (event) => { setStartDate(event.value); }
  const handleEndDateChangeEvent = (event) => { setEndDate(event.value); }
  const handleCustodianChangeEvent = (newVal) => { setCustodianCode(newVal); }
  const handleAuthorityChangeEvent = (newVal) => { setAuthorityCode(newVal); }
  const handleSpecialDesignationTypeChangeEvent = (newVal) => { setSpecialDesignationType(newVal); }
  const handleDescriptionChangeEvent = (event) => { setDescription(event.target.value); }
  const handleSpecificLocationChangeEvent = (event) => { setSpecificLocation(event.target.value); }

  const today = getToday();

  function GetCurrentData() {

    return {
      "pkId": formData.pkId,
      "changeType": (formData.pkId && formData.pkId > 0 ? "C" : "I"),
      "proOrder": 0,
      "usrn": formData.usrn,
      "custodianCode": custodianCode,
      "seqNum": formData.seqNum,
      "authorityCode": authorityCode,
      "specialDesignationCode": specialDesignationType,
      "wholeRoad": appliesToWholeRoad,
      "specificLocation": (!appliesToWholeRoad ? specificLocation : null),
      "description": description,
      "state": asdState,
      "entryDate": formData.entryDate,
      "startDate": startDate,
      "lastUpdateDate": today,
      "endDate": endDate,
      "wktGeometry": null,
      "lastUpdated": null,
      "isLocal": true
    }
  }

  async function handleSaveClick() {

    const saveData = GetCurrentData();

    setSpecialDesignationTypeError(null);
    setCustodianCodeError(null);
    setAuthorityCodeError(null);
    setDescriptionError(null);
    setStartDateError(null);
    setEndDateError(null);
    setSpecificLocationError(null);
    setWholeRoadError(null);
    setDbError(null);
    setHaveError(false);

    const validationErrors = await onSaveClick(saveData);

    if (validationErrors && validationErrors.length > 0) {
      setHaveError(true);

      for (var z = 0; z < validationErrors.length; z++) {
        switch (validationErrors[z].key) {
          case "specialDesignationCode":
            setSpecialDesignationTypeError(validationErrors[z].error);
            break;

          case "custodianCode":
            setCustodianCodeError(validationErrors[z].error);
            break;

          case "authorityCode":
            setAuthorityCodeError(validationErrors[z].error);
            break;

          case "description":
            setDescriptionError(validationErrors[z].error);
            break;

          case "startDate":
            setStartDateError(validationErrors[z].error);
            break;

          case "endDate":
            setEndDateError(validationErrors[z].error);
            break;

          case "specificLocation":
            setSpecificLocationError(validationErrors[z].error);
            break;

          case "wholeRoad":
            setWholeRoadError(validationErrors[z].error);
            break;

          case "dbError":
            setDbError(validationErrors[z].error);
            break;
        }
      }
    }
  }

  const handleCopyClick = () => {

    let copyData = { ...formData };

    copyData.pkId = -1;
    copyData.changeType = "I";
    copyData.proOrder = 0;
    copyData.seqNum = 0;
    copyData.entryDate = today;
    copyData.startDate = today;
    copyData.lastUpdateDate = today;
    copyData.endDate = null;
    copyData.lastUpdated = null;
    setTitle(`Special Designation (Copied) ${dataIdx + 1} of ${dataLen + 1}`);
    setFormData(copyData);
  }


  const handleDeleteClick = () => {
    const pkId = (formData && formData.pkId ? formData.pkId : -1);
    onDeleteClick(pkId);
  }

  const handleReturnClick = () => {
    const saveData = (isEditable) ? GetCurrentData() : formData;
    onReturnClick(formData, saveData);
  }

  const handleStateChange = (newState) => {
    setAsdState(newState);

    if (newState === 2 && !endDate) {
      setEndDate(today);
    } else if (newState === 1 && endDate) {
      setEndDate(null);
    }
  }

  const handleWholeRoadChange = (isWholeRoad) => {
    setAppliesToWholeRoad(isWholeRoad);
    onWholeRoadChange(isWholeRoad);
  }

  useEffect(() => {
    if(isEditable)
      logEdit(formData, GetCurrentData());
  }, [appliesToWholeRoad, startDate, endDate, custodianCode, authorityCode, specialDesignationType, description]);

  
  return (

    <Grid
      container
      direction="column"
      justify="space-around"
      alignItems="baseline"
      className={classes.nested}
    >
      <Grid container>
        <Grid item xs="8" justify="left">
          <Typography variant="body1">{title}</Typography>
        </Grid>

        <Grid item xs="3" justify="left">
          {(isEditable ?
            <React.Fragment>
              <IconButton size="small" onClick={handleSaveClick}>
                <Tooltip title="Save" arrow placement="top">
                  <SaveIcon color="primary" />
                </Tooltip>
              </IconButton>
              {(isEditable && (formData.pkId > 0) ?
                <IconButton size="small" onClick={handleCopyClick}>
                  <Tooltip title="Copy" arrow placement="top">
                    <FileCopyIcon className={classes.orange} />
                  </Tooltip>
                </IconButton>
                : null)}
              <IconButton size="small" onClick={handleDeleteClick}>
                <Tooltip title="Delete" arrow placement="top">
                  <DeleteIcon color="secondary" />
                </Tooltip>
              </IconButton>
            </React.Fragment>
            : null)
          }
          <IconButton size="small" onClick={handleReturnClick}>
            <Tooltip title="Return" arrow placement="top">
              <KeyboardReturnIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {haveError ? <Typography variant="subtitle2" color="error">Please correct the validation errors listed below before trying to save again.</Typography> : ""}

      </Grid>

      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2" color={specialDesignationTypeError === null ? "initial" : "error"}>Designation</Typography>
        </Grid>
        <Grid item xs="8" justify="left">
          <ASD_SpecialDesignationTypeDropDown type_data={Lookup_OSGSpecialDesignationCodes} selectedValue={specialDesignationType} changeHandler={handleSpecialDesignationTypeChangeEvent} dataRequired={true} />
        </Grid>
        <AAErrorDisplay errorText={specialDesignationTypeError} />
      </Grid>


      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2" color={custodianCodeError === null ? "initial" : "error"}>Custodian</Typography>
        </Grid>
        <Grid item xs="8" justify="left">
          <ASD_CustodianDropDown custodian_data={Lookup_OSGSwaOrgRefs} selectedValue={custodianCode} changeHandler={handleCustodianChangeEvent} dataRequired={true} />
        </Grid>
        <AAErrorDisplay errorText={custodianCodeError} />
      </Grid>

      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2" color={authorityCodeError === null ? "initial" : "error"}>Authority</Typography>
        </Grid>
        <Grid item xs="8" justify="left">
          <ASD_AuthorityDropDown authority_data={Lookup_OSGSwaOrgRefs} selectedValue={authorityCode} changeHandler={handleAuthorityChangeEvent} placeholder_text="choose Authority" dataRequired={true} />
        </Grid>
        <AAErrorDisplay errorText={authorityCodeError} />
      </Grid>

      <Grid container className={classes.formrow} >
        <Grid item xs="4" justify="left">
          <Typography variant="body2" color={descriptionError === null ? "initial" : "error"}>Description</Typography>
        </Grid>
        <Grid item xs="8" justify="left">
          <TextField
            value={description}
            id="description_edit"
            label={descriptionError !== null ? "Error" : "description"}
            error={descriptionError !== null}
            multiline
            rows={4}
            disabled={!isEditable}
            variant="outlined"
            helperText={descriptionError !== null ? descriptionError.toString() : "e.g. Afflochie Bridge, No U411/2, Reinforced Concrete Beam & Slab over Cruick Water"}
            onChange={handleDescriptionChangeEvent}
          />
        </Grid>
        {(description !== null && description.length > 0) ? <React.Fragment><Grid item xs="10"></Grid><Grid item xs="2" justify="left"><Typography variant="body2">{description.length} of 255</Typography></Grid></React.Fragment> : ""}
      </Grid>



      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2" color={startDateError === null ? "initial" : "error"}>Start Date</Typography>
        </Grid>
        <Grid item xs="8" justify="left">
          <ASD_ShortDatePicker dateValue={startDate} handleDateChangeEvent={handleStartDateChangeEvent} isEditable={isEditable} dataRequired={true} validData={startDateError === null} />
        </Grid>
        <AAErrorDisplay errorText={startDateError} />
      </Grid>

      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2" color={endDateError === null ? "initial" : "error"}>End Date</Typography>
        </Grid>
        <Grid item xs="8" justify="left">
          <ASD_ShortDatePicker dateValue={endDate} handleDateChangeEvent={handleEndDateChangeEvent} isEditable={isEditable && asdState === 2} dataRequired={asdState === 2} validData={endDateError === null} />
        </Grid>
        <AAErrorDisplay errorText={endDateError} />
      </Grid>

      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2">Entry Date</Typography>
        </Grid>
        <Grid item xs="8" justify="left">
          <Typography variant="body2" gutterBottom>{formData.entryDate ? moment(formData.entryDate).format("DD MMMM YYYY") : ""}</Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2">Last Update Date</Typography>
        </Grid>
        <Grid item xs="8" justify="left">
          <Typography variant="body2" gutterBottom>{formData.lastUpdateDate ? moment(formData.lastUpdateDate).format("DD MMMM YYYY") : ""}</Typography>
        </Grid>
      </Grid>


      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2">State</Typography>
        </Grid>
        <Grid item xs="3" justify="center">
          <Chip size="medium" disabled={!isEditable} avatar={<Avatar>1</Avatar>} label="Current" onClick={() => handleStateChange(1)} color={asdState === 1 ? "primary" : "default"} />
        </Grid>
        <Grid item xs="3" justify="center">
          <Chip size="medium" disabled={!isEditable} avatar={<Avatar>2</Avatar>} label="Historic" onClick={() => handleStateChange(2)} color={asdState === 2 ? "primary" : "default"} />
        </Grid>
      </Grid>

      <Grid container className={classes.formrow}>
        <Grid item xs="4" justify="left">
          <Typography variant="body2" color={wholeRoadError === null ? "initial" : "error"}>Applies to</Typography>
        </Grid>
        <Grid item xs="3" justify="center">
          <Chip size="medium" disabled={!isEditable} label="Whole Road" onClick={() => handleWholeRoadChange(true)} color={appliesToWholeRoad ? "primary" : "default"} />
        </Grid>
        <Grid item xs="3" justify="center">
          <Chip size="medium" disabled={!isEditable} label="Part of Road" onClick={() => handleWholeRoadChange(false)} color={!appliesToWholeRoad ? "primary" : "default"} />
        </Grid>
        <AAErrorDisplay errorText={wholeRoadError} />
      </Grid>

      <Grid container hidden={appliesToWholeRoad}>

        <Grid container className={classes.formrow} >
          <Grid item xs="4" justify="left">
            <Typography variant="body2" color={specificLocationError === null ? "initial" : "error"}>Specify location</Typography>
          </Grid>
          <Grid item xs="8" justify="left">
            <TextField
              value={specificLocation}
              id="specify_location_edit"
              label={specificLocationError !== null ? "Error" : "location"}
              error={specificLocationError !== null}
              multiline
              rows={4}
              disabled={!isEditable}
              variant="outlined"
              helperText={specificLocationError !== null ? specificLocationError.toString() : "e.g. Green Street from junction with Red Street to a point 75m beyond centreline of access to No 56."}
              onChange={handleSpecificLocationChangeEvent}
            />
          </Grid>
          {(specificLocation !== null && specificLocation.length > 0) ? <React.Fragment><Grid item xs="10"></Grid><Grid item xs="2" justify="left"><Typography variant="body2">{specificLocation.length} of 250</Typography></Grid></React.Fragment> : ""}
        </Grid>
      </Grid>
      {dbError !== null ? <Typography variant="subtitle2" color="error">{dbError}</Typography> : ""}
    </Grid>

  );
};


/*
const AssociatedStreetDataForm = (props) => {
    const classes = useStyles();
    const { data } = props;

};
*/

export default SpecialDesignationForm;
