/**************************************************************************************************
//
//  Description: Form for data related to ESU
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   09.03.21 Sean Flook         WI39240 Initial version.
//    002   09.03.21 Sean Flook         WI39240 Corrected width.
//    003   10.03.21 Sean Flook         WI39198 Updated the return icon.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */

import React, { useState } from "react";
import { Typography, Grid, IconButton, Tooltip } from "@material-ui/core";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import AAAvatarSelect from "../components/AAAvatarSelect";
import moment from 'moment';

/* #endregion imports */

const stateData = [
  { "id": 1, "description": "Under construction" },
  { "id": 2, "description": "Open" },
  { "id": 3, "description": "Proposed" },
  { "id": 4, "description": "Permanently closed" }
];

const classificationData = [
  { "id": 4, "description": "Pedestrian way or footpath" },
  { "id": 6, "description": "Cycle track" },
  { "id": 8, "description": "Open to vehicles" },
  { "id": 11, "description": "Motorway" },
  { "id": 12, "description": "Special road" },
  { "id": 13, "description": "Car park" }
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formrow: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    alignItems: "center",
  },
}));

const ESUForm = (props) => {
  const classes = useStyles();
  const handleEsuChange = props.changeHandler;
  const elementarystreetunits = props.data.streetCrossRefs;

  const openEsu = { color: 'black' };
  const closedEsu = { color: 'grey' };

  const [esuData, setEsuData] = useState();

  const itemSelect = (esu) => {
    setEsuData(esu);

    if (handleEsuChange)
      handleEsuChange("Esu", esu.esuId);
  }

  const handleReturnClick = () => {
    setEsuData(null);

    if (handleEsuChange)
      handleEsuChange("", null);
  }

  const renderForm = (esuData) => {
    let formComp = null;
    console.log("DEBUG renderForm", esuData);

    if (esuData) {
      formComp = (
        <Grid
          container
          direction="column"
          alignItems="baseline"
          className={classes.nested}
        >
          <Grid container className={classes.formrow}>
            <Grid item xs="9" justify="left">
              <Typography variant="body1">{esuData.esuId}</Typography>
            </Grid>

            <Grid item xs="3" justify="left">
              <IconButton size="small" onClick={handleReturnClick}>
                <Tooltip title="Return" arrow placement="top">
                  <KeyboardReturnIcon />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>

          <Grid container className={classes.formrow}>
            <Grid item xs="4" justify="left">
              <Typography variant="body2">State</Typography>
            </Grid>
            <Grid item xs="4" justify="left">
              <AAAvatarSelect
                data={stateData}
                placeholder="choose state"
                disabled="true" //SF WI38224
                avatarField="id"
                textField="description"
                keyField="id"
                selectedKey={esuData.state}
                colour="cyan"
                className="form__component"
              />
            </Grid>
          </Grid>

          <Grid container className={classes.formrow}>
            <Grid item xs="4" justify="left">
              <Typography variant="body2">State Date</Typography>
            </Grid>
            <Grid item xs="4" justify="left">
              <Typography className="form__component" variant="body2" gutterBottom>{moment(esuData.stateDate).format("DD MMMM YYYY")}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.formrow}>
            <Grid item xs="4" justify="left">
              <Typography variant="body2">Classification</Typography>
            </Grid>
            <Grid item xs="4" justify="left">
              <AAAvatarSelect
                data={classificationData}
                placeholder="choose classification"
                disabled="true" //SF WI38224
                avatarField="id"
                textField="description"
                keyField="id"
                selectedKey={esuData.classification}
                colour="orange"
                className="form__component"
              />
            </Grid>
          </Grid>

          <Grid container className={classes.formrow}>
            <Grid item xs="4" justify="left">
              <Typography variant="body2">Classification Date</Typography>
            </Grid>
            <Grid item xs="4" justify="left">
              <Typography className="form__component" variant="body2" gutterBottom>{moment(esuData.classificationDate).format("DD MMMM YYYY")}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.formrow}>
            <Grid item xs="4" justify="left">
              <Typography variant="body2">Entry Date</Typography>
            </Grid>
            <Grid item xs="4" justify="left">
              <Typography className="form__component" variant="body2" gutterBottom>{moment(esuData.entryDate).format("DD MMMM YYYY")}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.formrow}>
            <Grid item xs="4" justify="left">
              <Typography variant="body2">Start Date</Typography>
            </Grid>
            <Grid item xs="4" justify="left">
              <Typography className="form__component" variant="body2" gutterBottom>{moment(esuData.startDate).format("DD MMMM YYYY")}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.formrow}>
            <Grid item xs="4" justify="left">
              <Typography variant="body2">Last Update Date</Typography>
            </Grid>
            <Grid item xs="4" justify="left">
              <Typography className="form__component" variant="body2" gutterBottom>{moment(esuData.lastUpdateDate).format("DD MMMM YYYY")}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.formrow}>
            <Grid item xs="4" justify="left">
              <Typography variant="body2">End Date</Typography>
            </Grid>
            <Grid item xs="4" justify="left">
              <Typography className="form__component" variant="body2" gutterBottom>{esuData.endDate ? moment(esuData.endDate).format("DD MMMM YYYY") : ""}</Typography>
            </Grid>
          </Grid>

        </Grid>
        );
    } else {
      formComp = (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" disableGutters={true}>
              <Grid
                container
                direction="column"
                alignItems="baseline"
              >
                <Grid container alignItems="baseline">
                  <Grid item xs="6">
                    <Typography variant="body1">Elementary Street Units</Typography>
                  </Grid>
                  <Grid item xs="4">
                    <Avatar className={classes.small}>
                      <Typography variant="caption">
                        {`${elementarystreetunits ? elementarystreetunits.length : 0} `}
                      </Typography>
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>
            </ListSubheader>
          }
          className={classes.root}
        >
          {elementarystreetunits && elementarystreetunits.map((esu) => (
            <ListItem button onClick={() => itemSelect(esu.esuNavigation)}>
              <ListItemText primary={esu.endDate ? esu.esuId : < strong > {esu.esuId}</strong>} primaryTypographyProps={{ style: esu.endDate ? closedEsu : openEsu }} />
            </ListItem>
          ))}
        </List>
        );
    }
    return formComp;
  }

  return (renderForm(esuData))
};

export default ESUForm;
