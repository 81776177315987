//#region header */
/**************************************************************************************************
//
//  Description: Page used when not in street details
//
//  Copyright:    � 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   06.01.21 Sean Flook                 Added USRN to search methods.
//    003   11.02.21 Sean Flook         WI38232 Updated the build date.
//    004   18.02.21 Sean Flook         WI39168 Added About and Contact us.
//    005   03.03.21 Sean Flook         WI39183 Added copyright.
//    006   03.03.21 Sean Flook         WI38667 Added some content to the home page.
//    007   04.03.21 Sean Flook         WI38667 Use a picture to ensure the Transport Scotland logo is displayed.
//    008   11.03.21 Peter Bryden               Updated the build date, version
//    009   12.03.21 Sean Flook         WI39268 Added the last export date.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    010   01.06.21 Paula Dormer       WI39308 With Pete's help, corrected the Last export display 
//                                              when an export has never run.
//    011   01.06.21 Paula Dormer       WI39308 Updated the version and updated date
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import Slide from '@material-ui/core/Slide';
import { Box } from "@material-ui/core";
import history from "../history";
import moment from 'moment';
import { getLastExportDateAsync } from '../services/api';
/* #endregion imports */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = ({ breakpoints }) => ({
  root: {
    padding: 16,
    [breakpoints.up("sm")]: {
      padding: 24,
      maxWidth: 500,
      margin: "auto",
    },
    [breakpoints.up("md")]: {
      maxWidth: 700,
    },
  },
});

const Homepage = ({ classes }) => {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [exportDate, setExportDate] = React.useState();
  const currentVersion = "Version: 1.0.1 (updated 05 July 2021)";

  const handleTSLogoClick = () => {
    history.push("/gazetteer/8002119", "Port Dundas Road")
  };

  const handleContactClickOpen = () => {
    setContent(
      <React.Fragment>
        <Typography align="center" variant="subtitle1">Contact Aligned Assets Ltd.</Typography><br />
        <Typography align={"left"} variant="subtitle2">Address:</Typography>
        <Typography align={"left"} variant="body2">Unit 5, Woking 8</Typography>
        <Typography align={"left"} variant="body2">Forsyth Road</Typography>
        <Typography align={"left"} variant="body2">Sheerwater</Typography>
        <Typography align={"left"} variant="body2">Woking</Typography>
        <Typography align={"left"} variant="body2">Surrey</Typography>
        <Typography align={"left"} variant="body2">GU21 5SB</Typography><br />
        <Typography align={"left"} variant="subtitle2">Telephone:</Typography>
        <Typography align={"left"} variant="body2">+44 (0) 1483 717 950</Typography><br />
        <Typography align={"left"} variant="subtitle2">Portal:</Typography>
        <Link align={"left"} href="https://portal.aligned-assets.co.uk" target="_blank" rel="noopener" variant="body2">Aligned Assets Customer Portal</Link><br /><br />
        <Typography align={"left"} variant="subtitle2">Web:</Typography>
        <Link align={"left"} href="https://www.aligned-assets.co.uk/contact-us/" target="_blank" rel="noopener" variant="body2">https://www.aligned-assets.co.uk/contact-us/</Link><br /><br />
        <Typography align={"left"} variant="subtitle2">Email:</Typography>
        <Link align={"left"} href="mailto:solutions@aligned-assets.co.uk" variant="body2">General: solutions@aligned-assets.co.uk</Link><br />
        <Link align={"left"} href="mailto:support@aligned-assets.co.uk?subject=Scottish Trunk Roads" variant="body2">Support: support@aligned-assets.co.uk</Link>
      </React.Fragment>
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

//PD WI39308++
    const isStringInDateFormat = (dateStr) => {
        const dateResult = new Date(dateStr);
        return (dateResult instanceof Date && !isNaN(dateResult.valueOf()));
    }


/* HOOKS*/

    useEffect(() => {
        async function getLastExportDate() {
            await getLastExportDateAsync()
                .then((result) => {
                    setExportDate(isStringInDateFormat(result) ? new moment(result) : null);
                })
                .catch((err) => { setExportDate(null) });
        }
        if (!exportDate)
            getLastExportDate();

    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
//PD WI39308--



  return (
    <div className={classes.root} >
      <Grid className="homePage" container justify="space-around" spacing={0}>
        <Grid item xs={12}>
          <Typography weight={"bold"} variant={"h3"} gutterBottom align="center">Scottish Trunk Road Gazetteer</Typography>
          <Typography variant={"subtitle1"} gutterBottom align="center">Developed by</Typography>
          <Box className="home__box" align="center" mt={2}>
            <Link className="Home-link" align="center" color={"textSecondary"} onClick={handleContactClickOpen}>
              <img
                className="AA-logo"
                src="/images/aligned_assets_logo_text.svg"
                alt="Aligned Assets"
                />
            </Link>
          </Box>
          <Typography variant={"subtitle1"} align="center">for</Typography>
          <Box className="home__box" align="center" mt={2}>
            <picture>
              <source type="image/webp" srcset="/images/Transport_Scotland.webp 1x" />
              <source type="image/jpg" srcset="/images/Transport_Scotland.jpg 1x" />
              <img
                className="TS-logo"
                src="/images/Transport_Scotland.jpg"
                alt="Transport Scotland"
                onClick={handleTSLogoClick}
                />
            </picture>
          </Box>
          <Typography variant={"subtitle2"} gutterBottom align="center">{currentVersion}</Typography>
        </Grid>
        <Grid item xs={12}>
                  <Typography className="home__strong" variant={"body1"} indent={"small"} align="center">{`Data last exported: ${exportDate ? exportDate.format("DD/MM/YYYY HH:mm:ss") : "No Export performed."}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="home__strong" variant={"body1"} indent={"small"} align="center">Start by typing an address or USRN in the search bar in the top right hand of the header</Typography>
        </Grid>
        <Grid container item xs={12} spacing={0} alignItems="flex-end">
          <div>
            <Typography variant="body2" align="center">{"Copyright Aligned Assets Ltd. 2020 - " + (new Date().getFullYear())}</Typography>
            <Box className="home__box" align="center" mt={2}><Link className="Home-link" align="center" color={"textSecondary"} onClick={handleContactClickOpen}><Typography variant="body1" align="center" gutterBottom>Contact us</Typography></Link></Box>
            <Typography variant="body2" align="center">Warning: copyright law and international treaties protect this site. Unauthorised reproduction or distribution of the site, or any portion of it, may result in severe civil and criminal penalties, and will be prosecuted to the maximum extent of the law.</Typography>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent id="alert-dialog-slide-content">{content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

Homepage.propTypes = {};
Homepage.defaultProps = {};

export default withStyles(styles)(Homepage);
