/**************************************************************************************************
//
//  Description:  services related to lookups
//
//  Copyright:    © 2020-21 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   22.2.21 Joel Benford        WI39069 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

//#region imports
import moment from 'moment';

import { store } from "./../store/store";
import * as api from "./api";

import { lookupsRefreshedSet } from "../store/ui/lookupsRefreshed";

import { administrativeAreasSet } from '../store/entities/administrativeAreas';
import { localitiesSet } from '../store/entities/localities';
import { osgLanguagesSet } from '../store/entities/osgLanguages';
import { osgSwaOrgRefsSet } from '../store/entities/osgSwaOrgRefs';
import { streetRefsSet } from '../store/entities/streetRefs';
import { townsSet } from '../store/entities/towns';
//#endregion imports

//checks whether lookups are dirty and if so refreshes
export const checkGetLookupsAsync = async () => {
  //console.log("trace checkLookups starting");
  const lookupsRefreshedApi = new moment(await api.getLookupsLastChangedAsync());
  //console.log("trace lookupsRefreshedApi", lookupsRefreshedApi);
  const lookupsRefreshedStore = new moment(await store.getState().ui.lookupsRefreshed);
  //console.log("trace lookupsRefreshedStore", lookupsRefreshedStore);
  const lookupsDirty = lookupsRefreshedApi.isAfter(lookupsRefreshedStore);
  //console.log("trace lookupsDirty", lookupsDirty);

  if (lookupsDirty) {
    await store.dispatch(administrativeAreasSet(await api.getAdministrativeAreasAsync()));
    await store.dispatch(localitiesSet(await api.getLocalitiesAsync()));
    await store.dispatch(osgLanguagesSet(await api.getOSGLanguagesAsync()));
    await store.dispatch(osgSwaOrgRefsSet(await api.getOSGSwaOrgRefsAsync()));
    await store.dispatch(streetRefsSet(await api.getStreetRefsAsync()));
    await store.dispatch(townsSet(await api.getTownsAsync()));

    await store.dispatch(lookupsRefreshedSet(lookupsRefreshedApi.format("YYYY-MM-DDTHH:mm:ss.SSSSSSS")));
  }

}
