/**************************************************************************************************
//
//  Description: MaintenanceASDSelectionPage page
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.1.0 changes
//    001   27.05.21 Peter Bryden        WI39324 Initial Revision.
//    002   04.06.21 Peter Bryden        WI39324 Add in call API call on move buuton
//    003   04.06.21 Paula Dormer        WI39308 Add code for closing selected ASD records
//    004   08.06.21 Paula Dormer        WI39308 Change the wording of the display messages and
//                                               if the street that was selected isn't editable, 
//                                               don't enable the move button
//    005   29.06.21 Paula Dormer        WI39308 Add the Only return ASD belonging tothe Operator.
//    006   29.06.21 Paula Dormer        WI39308 Add the Operatorid to MaintenanceASDListView call.
//    007   01.07.21 Paula Dormer        WI39308 Display the Usrn of the closed street.
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, FormControl, FormControlLabel, FormGroup } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { Alert } from "@material-ui/lab";

import { MaintenanceASDListView } from "../components/maintenancepage/MaintenanceASDListView"

import { useParams } from "react-router-dom";

import {
  GetCanOperatorEditASDDataURL,
    MoveClosedStreetASDsURL,
    CloseSelectedASDRecordsURL
} from '../configuration/AAConfig'


import authService from '../components/api-authorization/AuthorizeService';

import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import history from "../history";
import AAStreetSearch from "../components/AAStreetSearch";


const apifetch = async (url, dataIfAborted, signal) => {
  try {
    const response = await fetch(url, { signal })

    const data = await response.json();
    return data;
  } catch (err) {
    if (err.name === "AbortError") {
      console.log("Request Aborted ");
      return dataIfAborted;
    }
    return err;
  }
};


/* #endregion imports */

const MaintenanceASDSelectionPage = () => {

  const urlParameters = useParams();
   
   
  const [apiURLs, setApiURLs] = useState(null);

  const [closedUSRN, setClosedUSRN] = useState(urlParameters.maintenanceUSRN);
  const [operatorId, setOperatorId] = useState();
    const [selectedASDs, setSelectedASDs] = useState([]);
    const [asdEditable, setAsdEditable] = useState(false);

  const [destUSRN, setDestUSRN] = useState();
  const [updateASDs, setUpdateASDs] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const getOperatorId = () => { return operatorId; }

  /* HOOKS*/


  useEffect(() => {

    async function populateOperator() {
      const [authenticated] = await Promise.all([authService.isAuthenticated()])

      if (authenticated && operatorId == null) {
        const token = await authService.getAccessToken();
        const response = await fetch('user', {
          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(res => (res.ok ? res : Promise.reject(res)))
          .then(res => res.json())
          .then(
            (result) => {

        //      console.log(" *** populateOperator ***", result);
              if (result)
                setOperatorId(result.operatorid);
            },
            (error) => {
              console.log("Get operator Error", error);
              if (error.status === 401) {
                const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
                history.push(logoutPath);
              }
            }
          );
      }
    };

    const _subscription = authService.subscribe(() => populateOperator());

    async function SetUpApi() {
      if (apiURLs == null) {
        const canEditUrl = await GetCanOperatorEditASDDataURL();
          const moveURL = await MoveClosedStreetASDsURL();
          const closeURL = await CloseSelectedASDRecordsURL();
          setApiURLs({ canEditASD: canEditUrl, moveURL: moveURL, closeURL: closeURL});
      }
    }

    if (operatorId == null)
      populateOperator();

    if (apiURLs === null)
      SetUpApi();

 
    return () => {
      authService.unsubscribe(_subscription);
    };

  }, []);

    const handleDestinationChange = (usrnVal, streetVal) => { setDestUSRN(usrnVal);}

  const handleSelectedASD = (selectedData) => {
    const asdMoveData = selectedData && selectedData.map((item) => { return { "RecType": item.asdType, "PkId": item.id }; });
    setSelectedASDs(asdMoveData);
  }

  const handleDataLoaded = (isDataLoaded) => { setDataLoaded(isDataLoaded); }

  function showChooseDestinationInfo() {
      if (isNaN(destUSRN))
      return (<Alert variant="filled" severity="info">If moving ASD, please choose a destination USRN.</Alert>);

    CanOperatorEditASD();

    if (!asdEditable)
      return (<Alert variant="outlined" severity="error">This street is closed or you don't have access to edit it.</Alert>);

                 
  } 

  function showSelectASDInfo() {
    return ((!selectedASDs || selectedASDs.length === 0) ?
      <Box pt="2em" pb="2em">
        <Alert variant="filled" severity="info">Please select which ASDs you wish to move or close!</Alert>
        </Box>
      : ""
    );
  }

  const handleMoveASDs = async () => {

    const moveURL = apiURLs.moveURL.url;
    const url = `${moveURL}/${operatorId}/${closedUSRN}/${destUSRN}`;
    const httpMethod = apiURLs.moveURL.type;
    const postedArray = JSON.stringify(selectedASDs);


    console.log("handleMoveASDs = ", url, postedArray);
       
    await fetch(url, {
      method: httpMethod,
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: postedArray
    })
      .then(res => (res.ok ? res : res.text().then(text => { throw new Error(text) })))
      //.then(res => (saveMethod === "POST") ? res.json() : res)
      .then(
        (result) => {
          setUpdateASDs(!updateASDs);
          setSelectedASDs([]);
        }
      )
      .catch(errorMessage => {
        console.log("ERROR SaveForm", errorMessage);
      });
      
    }

    //PD WI39308++

    const handleCloseASDs = async () => {

        const closeURL = apiURLs.closeURL.url;
        const url = `${closeURL}/${operatorId}/${closedUSRN}`;
        const httpMethod = apiURLs.closeURL.type;
        const postedArray = JSON.stringify(selectedASDs);
 
        await fetch(url, {
          method: httpMethod,
          cache: 'no-cache',
          headers: { 'Content-Type': 'application/json' },
            body: postedArray
        })
          .then(res => (res.ok ? res : res.text().then(text => { throw new Error(text) })))
          //.then(res => (saveMethod === "POST") ? res.json() : res)
          .then(
              (result) => {
                  setUpdateASDs(!updateASDs);
                  setSelectedASDs([]);
    
            }
          )
      .catch(errorMessage => {
        console.log("ERROR SaveForm", errorMessage);
          });
         
         
    }

    const CanOperatorEditASD = () => {
        if (destUSRN && getOperatorId()) {
            if (apiURLs && apiURLs.canEditASD !== null) {
                const canEditASD = apiURLs.canEditASD;
                console.log("fetching CanUserEditASDData", destUSRN, `${canEditASD.url}/${getOperatorId()}/${destUSRN}`);
                fetch(`${canEditASD.url}/${getOperatorId()}/${destUSRN}`)
                    .then(res => (res.ok ? res : Promise.reject(res)))
                    .then(res => res.json())
                    .then(
                        (result) => { 
                              setAsdEditable(result);

                        }, 
                        (error) => {
                            console.log("Cannot determine if user can edit ASD data = ", error);
                        }
                    );
            } else
            {
                console.log("canEditASD is null");
            }
        }
    }
    //PD WI39308--


  return (
              
        <Grid container justify="flex-start" pt={2}>
            <Grid item xs={12}>
                <Grid container justify="flex-start">
          <Grid item xs={5} >
            <Typography variant="subtitle2" className="displayStreet" >{"Closed USRN - " + closedUSRN}</Typography> 
              <MaintenanceASDListView usrn={closedUSRN} handleChecked={handleSelectedASD} reloadData={updateASDs} handleDataLoaded={(isDataLoaded) => handleDataLoaded(isDataLoaded)} operatorID={operatorId} />
                    </Grid>
                <Grid item xs={4}>
                        <Box pt={4}>
                            <FormControl fullWidth={true} >
                                <FormGroup>
                                        {showChooseDestinationInfo()}
                                    <FormControlLabel
                                        control={<AAStreetSearch handleChange={handleDestinationChange} />}
                                        label="Destination"
                                        labelPlacement="start"
                                        width="100%"
                                    /> 
                                </FormGroup>
                            </FormControl>
                        </Box>

                        <Box pt={4} >
                            {showSelectASDInfo()}
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CloudUploadIcon />}
                                disabled={!dataLoaded || !((selectedASDs.length > 0) && (!isNaN(destUSRN)) && asdEditable)}
                                onClick={handleMoveASDs}
                            >
                                Move {selectedASDs && selectedASDs.length > 0 ? `${selectedASDs.length} items` : `items`}
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelPresentationIcon />}
                                disabled={!dataLoaded || !(selectedASDs.length > 0)}
                                onClick={handleCloseASDs}
                            >
                                Close {selectedASDs && selectedASDs.length > 0 ? `${selectedASDs.length} items` : `items`}
                            </Button>
                        </Box>

                        <Box pt={4}>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<LocationSearchingIcon />}
                                href="\maintenance"
                            >
                                Return to Maintenance USRN Selection
                </Button>
                        </Box>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    );
};


export default MaintenanceASDSelectionPage;
