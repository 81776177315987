/**************************************************************************************************
//
//  Description:  combines reducers for Entities slice of Redux store
//
//  Copyright:    © 2020-21 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   13.01.21 Joel Benford        WI39064 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { combineReducers } from "redux";
import administrativeAreasReducer from './administrativeAreas';
import localitiesReducer from './localities';
import osgLanguagesReducer from './osgLanguages';
import osgSwaOrgRefsReducer from './osgSwaOrgRefs';
import streetRefsReducer from './streetRefs';
import townsReducer from './towns';
// # endregion imports

export default combineReducers({
  administrativeAreas: administrativeAreasReducer,
  localities: localitiesReducer,
  osgLanguages: osgLanguagesReducer,
  osgSwaOrgRefs: osgSwaOrgRefsReducer,
  streetRefs: streetRefsReducer,
  towns: townsReducer
});