/**************************************************************************************************
//
//  Description: form for data related to ASD details
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.11.20 Peter Bryden               Initial Revision.
**************************************************************************************************/

export function getToday(){
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  const todayStr = `${yyyy}-${mm}-${dd}T00:00:00`;
  return todayStr;
}

