/**************************************************************************************************
//
//  Description: Maintenance Street Table View
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.1.0 changes
//    001   01.06.21 Peter Bryden        WI39324 show list of closed streets with asds
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Chip from '@material-ui/core/Chip';
const useStyles = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
      margin: theme.spacing(1.5),
      width: '100%',
    },

  table: {
      minWidth: 750,
    },

    // Default root styles
    color: 'inherit',
    chipColour: 'lightgray',
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,

    '&:hover': {
      color: 'white',
      // Set hover color
      backgroundColor: 'lightblue',
      chipColour: 'blue'
    },

  hover: {},
}));

export const MaintenanceStreetTableView = ({ streets, handleSelection }) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const classes = useStyles();

  const formatDate = (dateStr) => {
    var dt = new Date(dateStr);
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${dt.getDate()} ${months[dt.getMonth()]} ${dt.getFullYear()}`;
  }


  const handleChangePage = (event, newPage) => { setPage(newPage); };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    return (
    <div className={classes.root}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableBody>
              {streets && streets.slice(page, page + rowsPerPage).map((item) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => handleSelection(item.usrn)}
                      key={item.usrn}
                    >
                      <TableCell component="th" id={item.usrn} scope="row" padding="none">
                        <Typography variant="body1">
                          <Chip label={item.usrn} color="primary" />
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {formatDate(item.closureDate)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {item.descriptor}
                          <Typography variant="subtitle2" gutterBottom>
                            {`${item.town}, ${item.county}`}
                          </Typography>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={streets && streets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </div>
  );
}

