/**************************************************************************************************
//  Description:
//
//  Copyright:    � 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
/* #endregion imports */

/* #region styles */
const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
});
/* #endregion styles */

const Taskmanagementpage = ({ classes }) => {
  return (
    <div>
      <div>
        <Typography variant="h1" component="h2" gutterBottom>
          Task Management Page
        </Typography>
      </div>
    </div>
  );
};

Taskmanagementpage.propTypes = {
  //classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Taskmanagementpage);
