/**************************************************************************************************
//
//  Description:  lastRoute slice of Redux store
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   30.10.20 Joel Benford               Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { createSlice } from "@reduxjs/toolkit";
//#endregion imports 

//State
const slice = createSlice({
  name: "lastRoute",
  initialState: "/",
  reducers: {
    lastRouteChanged: (lastRoute, action) => {
      //console.log("lastRouteChanged",lastRoute, action);
      return action.payload
      //return lastRoute;
    }
  }
});

// Actions
export const { lastRouteChanged } = slice.actions;

// Reducers
export default slice.reducer;
