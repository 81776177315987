/**************************************************************************************************
//
//  Description:  ui/lookupsRefreshed slice of Redux store
//
//  Copyright:    © 2020-2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   26.01.21 Joel Benford        WI39069 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment';
//#endregion imports

//State
const slice = createSlice({
  name: "values",
  initialState: new moment(new Date(0)).format("YYYY-MM-DDTHH:mm:ss.SSSSSSS"), // "1970-01-01T01:00:00.0000000",
  reducers: {
    lookupsRefreshedSet: (stored, action) => {
      //console.log("trace reducer lookupsRefreshedSet incoming", action);
      return action.payload;
    }
  }
});

// Actions
export const { lookupsRefreshedSet } = slice.actions;

// Reducers
export default slice.reducer;
