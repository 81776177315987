//#region header */
/**************************************************************************************************
//
//  Description: User Admin Page
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   15.03.21 Peter Bryden               Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import authService from '../components/api-authorization/AuthorizeService';
import { GetOperatorsURL } from '../configuration/AAConfig'

import EditUserDialog from '../dialogs/EditUserDialog'
import ResetUserPasswordDialog from '../dialogs/ResetUserPasswordDialog'

/* #endregion imports */

const styles = ({ breakpoints }) => ({
  root: {
    padding: 10,
    [breakpoints.up("sm")]: {
      padding: 10,
      maxWidth: '50%',
      margin: "auto",
    },
    [breakpoints.up("md")]: {
      maxWidth: '90%',
    },
  },
});

const ButtonCommandCell = props => {
  return (
    <td>
      <Button variant="contained" color="primary" onClick={() => props.onClick(props.dataItem)}>
        {props.btnText}
      </Button>
    </td>
  );
};

const UserAdminpage = ({ classes }) => {

  const [users, setUsers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [editItem, setEditItem] = useState();
  const [showEditDialog, setShowEditDialog] = useState();
  const [showPasswordDialog, setShowPasswordDialog] = useState();
  const [passwordErrors, setPasswordErrors] = useState();

  useEffect(() => {

    console.log("user admin useeffect",passwordErrors);

    const loadOperators = async () => {

      const operators_url = await GetOperatorsURL();

      return await fetch(operators_url.url)
        .then(response => response.json())
        .then(result => {
          if (result) {
            setOperators(result);
            return result;
          }
        })
        .catch(e => {
          console.log(e);
          return [];
        });
    };

    const loadUsers = async () => {
      const [authenticated] = await Promise.all([authService.isAuthenticated()])

      if (authenticated) {

        const operatorList = (operators.length == 0) ? await loadOperators() : operators;

        const token = await authService.getAccessToken();
        const response = await fetch('user/list', {
          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(res => (res.ok ? res : Promise.reject(res)))
          .then(res => res.json())
          .then(
            (result) => {
              if (result) {
                const updatedUsers = result.map(u => {
                  const chosenOp = operatorList.find(({ operatorId }) => operatorId === u.operatorid);
                  u.operator = chosenOp ? chosenOp.operatorName : "";
                  return u;
                });
                setUsers(updatedUsers);
              }
            },
            (error) => {
              console.log("List Users Error", error);
            }
          );
      }
      else {
        console.log("authenticated ", authenticated);
      }
    };

    if (users.length === 0) {
      loadUsers();
    }

  }, [passwordErrors]);

  const openEditDialog = item => {
    setEditItem(item);
    setShowEditDialog(true);
  }

  const openPasswordDialog = item => {
    setEditItem(item);
    setShowPasswordDialog(true);
  }


  const EditCommand = props => (
    <ButtonCommandCell {...props} btnText={"Edit"} onClick={openEditDialog} />
  );

  const PasswordReset = props => (
    <ButtonCommandCell {...props} btnText={"Password"} onClick={openPasswordDialog} />
  );


  const handleCancelEditDialog = () => { setShowEditDialog(false); };

  const updateUser = async (editedUser) => {
    const [authenticated] = await Promise.all([authService.isAuthenticated()])

    if (authenticated) {

      const token = await authService.getAccessToken();
      const { id, name, operatorid } = editedUser;
      const response = await fetch(`user/updateuser/${id}/${name}/${operatorid}`, {
        method: 'PUT',
        cache: 'no-cache',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then(res => (res.ok ? res : Promise.reject(res)))
        .then(
          (result) => console.log("updateusers", result)
          ,
          (error) => {
            console.log("Update Users Error", error);
          }
        );
    }
    else {
      console.log("authenticated ", authenticated);
    }
  };

  const updatePassword = async (id,password) => {
    const [authenticated] = await Promise.all([authService.isAuthenticated()])

    if (authenticated) {

      const token = await authService.getAccessToken();

      const response = await fetch(`user/passwordreset/${id}/${password}`, {
        method: 'POST',
        cache: 'no-cache',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then(response => {
        if (response.status === 200) {
          return [];
        } else if (response.status == 404) {
          return response.json().then(errs => {
            return errs;
					})
        }
        else if (response.status === 401) {
          return ["authorization failed - please logout and log back in again"];
        }
      });

      return response;
    }
    else {
      //console.log("authenticated ", authenticated);
      return ["User Not Authenticated"];
    }
  };

  const handleSubmitEditDialog = (updated_user) => {
    updateUser(updated_user);
    console.log("submit edit form",updated_user);
    setShowEditDialog(false);
  }

  const handleClosePasswordDialog = async (id,password) => { setShowPasswordDialog(false); }

  return (
    <div className={classes.root}>
      <Typography variant={"overline"}>User Admin</Typography>

      <Grid
        style={{ height: '60vh', width:'85%' }}
        data={[...users]}
      >
        <Column field="id" width={0} show={false} sortable={false} resizable={false} locked={true} minResizableWidth={0} editable={false} />
        <Column field="email" title="Email" width="350px" />
        <Column field="name" title="Name" width="250px" />
        <Column field="operatorid" width={0} show={false} sortable={false} resizable={false} locked={true} minResizableWidth={0} editable={false} />
        <Column field="operator" title="Operator" width="350px" />
        <Column
          field="isAdmin" title="Admin" width="100px"
          cell={props => (
            <td>
              <input disabled type="checkbox" checked={props.dataItem[props.field]} />
            </td>
          )}
        />
        <Column cell={PasswordReset} width="145px" />
        <Column cell={EditCommand} width="120px" />
      </Grid>
      {showEditDialog && <EditUserDialog isOpen={showEditDialog} user={editItem} operators={operators} cancelHandler={handleCancelEditDialog} submitHandler={handleSubmitEditDialog} />}
      {showPasswordDialog && <ResetUserPasswordDialog isOpen={showPasswordDialog} user={editItem} handleCloseDialog={handleClosePasswordDialog} updatePasswordHandler={updatePassword} />}
    </div >
  );
}

export default withStyles(styles)(UserAdminpage);
