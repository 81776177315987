/**************************************************************************************************
//
//  Description:  Page Routing class
//
//  Copyright:    � 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   19.01.21 Sean Flook                 Corrected spelling of Gazetteer.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    003   27.05.21 Peter Bryden       WI39308 add in maintenance page routing
//    004   09.06.21 Peter Bryden       WI39308 make sure maintenance route is in currentroute
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

import Homepage from "./pages/Homepage";
import Gazetteerpage from "./pages/Gazetteerpage";
import Taskmanagementpage from "./pages/Taskmanagementpage";
import UserAdminpage from "./pages/UserAdminpage";
import Maintenancepage from "./pages/MaintenancePage";
import MaintenanceASDSelectionPage from "./pages/MaintenanceASDSelectionPage";

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';


/* #endregion imports */


export const HomeRoute = "/";
export const GazetteerRoute = "/gazetteer";
export const TaskManagementRoute = "/taskmanagement";
export const UserAdminRoute = "/useradmin";
export const MaintenanceRoute = "/maintenance";
export const ASDMaintenanceRoute = "/maintenance/:maintenanceUSRN";

export const CurrentRoute = () => {
  const currentRoute = window.location.pathname;

  if (currentRoute.startsWith(GazetteerRoute))
    return GazetteerRoute;
  else if (currentRoute.startsWith(MaintenanceRoute))
    return MaintenanceRoute;

  return HomeRoute;
};


const PageRouting = () => {
  return (
    <Switch>
      <AuthorizeRoute exact path={`${GazetteerRoute}/:usrn`} component={Gazetteerpage} />
      <AuthorizeRoute path={TaskManagementRoute} component={Taskmanagementpage} />
      <AuthorizeRoute path={UserAdminRoute} component={UserAdminpage} />

      <AuthorizeRoute exact path={MaintenanceRoute} component={Maintenancepage} />
      <AuthorizeRoute exact path={ASDMaintenanceRoute} component={MaintenanceASDSelectionPage} />

      <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      <AuthorizeRoute path="*" exact component={Homepage} />
    </Switch>
  )
};

export default withRouter(PageRouting);
